import React from "react";
import useFetch from "../hooks/useFetch";
import { Link } from "react-router-dom";
import { currencyData, ExchangeData } from "../currency";

const Recentpost = () => {
  // recent post
  const currency = currencyData();
  const Rate = ExchangeData();
  const Z = currency == "ETB" ? 1 : Rate;
  const { data, loading, error } = useFetch(
    `/products?populate=*&sort[0]=id:desc `
  );

  const selectpost = data;

  /* console.log('selectpost', selectpost) */

  return (
    <div>
      {/* Single Widget */}

      {data?.slice(0, 3).map((x) => (
        <div key={x.id}>
          <Link to={`/product/${x.id}`}>
            <div className="single-post  ">
              <div className="image">
                <img
                  src={
                    process.env.REACT_APP_UPLOAD_URL +
                    x.attributes?.gallery?.data[0].attributes?.url
                  }
                  alt="#"
                 style={{width:50,height:50}}
                />
              </div>
              <div className="content">
                <h5>
                  <a href="">{x.attributes?.title}</a>
                </h5>
                <p className="price">
                  {(x.attributes?.price * Z).toFixed(2)}{" "}
                  {currency == "ETB" ? " ETB" : " USD"}
                </p>
              </div>
            </div>
          </Link>
        </div>
      ))}

      {/* End Single Post */}
    </div>
  );
};

export default Recentpost;
