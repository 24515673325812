import React from "react";

import { UserData } from "../helper";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import axios from "axios";

const Profile = () => {
  const user_info = UserData();
  const [error, setError] = useState();
  const [payinfo, setPayinfo] = useState();
  const Cart_Products = useSelector((state) => state.Cart_Products);
  // console.log(user_info)
  /* console.log(Cart_Products,'Cart_Products') */
  const Email = user_info.email;

  // console.log(Email);
  const payment_info = async () => {
    const url = process.env.REACT_APP_API_URL + `/payment-info`;
    console.log(url, "url");
    const datas = {
      email: Email,
    };
    try {
      await axios
        .post(url, datas, {
          headers: {
            Authorization: "bearer " + process.env.REACT_APP_API_TOKEN,
          },
        })
        .then((response) => setPayinfo(response.data));
    } catch (error) {
      setError(error.response.data);
      console.log(error, "error");
    }
  };

  useEffect(() => {
    payment_info();
  }, []);

  console.log(payinfo, "payinfo");
  const pending = {
    textTransform: "uppercase",
    width: "5px",
    backgroundcolor: "yellow",
  };
  const failed = {
    textTransform: "uppercase",
    width: "5px",
    backgroundcolor: "red",
  };
  const success = {
    textTransform: "uppercase",
    width: "5px",
    backgroundcolor: "green",
  };
  return (
    <div>
      <>
        <div class="js" style={{ marginTop: "0rem", marginBottom: "40rem" }}>
          <div className="main-content position-relative   max-height-vh-100 h-100 ">
            <div className="container-fluid py-4">
              <div className="row">
                <div className="col-8  ">
                  <div className="newcard h-100" style={{ padding: "1rem" ,backgroundColor:'white'}}>
                    <div className="card-header pb-0 p-3">
                      <div className="row">
                        <div className="col-md-8 d-flex align-items-center">
                          <h6 className="mb-0">Profile Information</h6>
                        </div>
                        <div className="col-md-4 text-end">
                          <a href="javascript:;">
                            <i
                              className="fas fa-user-edit text-secondary text-sm"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit Profile"
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="card-body p-3">
                      <ul className="list-group">
                        <li className="list-group-item border-0 ps-0 pt-0 text-sm">
                          <strong className="text-dark">User Name:</strong>{" "}
                          &nbsp; {user_info.username}
                        </li>
                        <li className="list-group-item border-0 ps-0 pt-0 text-sm">
                          <strong className="text-dark">Full Name:</strong>{" "}
                          &nbsp; {user_info.first_name} {user_info.last_name}
                        </li>
                        <li className="list-group-item border-0 ps-0 text-sm">
                          <strong className="text-dark">Mobile:</strong> &nbsp;
                          {user_info.phone_number}
                        </li>
                        <li className="list-group-item border-0 ps-0 text-sm">
                          <strong className="text-dark">Email:</strong> &nbsp;
                          {user_info.email}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <>
            <main className="main-content position-relative max-height-vh-100 h-100 border-radius-lg ">
              <div className="container-fluid py-4 ">
                <div className="row">
                  <div className="col-lg-12 mt-4 ">
                    <div className="newcard " style={{ padding: "1rem",backgroundColor:'white' }}>
                      <div className="card-header pb-0 px-3">
                        <h6 className="mb-0">Billing Information</h6>
                      </div>
                      <div className="card-body pt-4 p-3">
                        <ul
                          className="list-group"
                          style={{ height: "30rem", overflow: "scroll" }}>
                          {payinfo?.map((item) => (
                            <div key={item.id}>
                              <li
                                className="row newcard"
                                style={{ padding: "1.5rem", margin: "0.5rem" }}>
                                <div className="col">
                                  <h6 className="mb-3 text-sm">Id:{item.id}</h6>
                                  <span className="mb-2 text-xs">
                                    Tx Ref:{" "}
                                    <span className="text-dark font-weight-bold ms-sm-2">
                                      {item.tx_ref}
                                    </span>
                                    <br></br>
                                  </span>
                                  <span className="mb-2 text-xs">
                                    Amount{" "}
                                    <span className="text-dark ms-sm-2 font-weight-bold">
                                      {item.amount.toFixed(2)}
                                    </span>
                                    <br></br>
                                  </span>
                                  <span className="text-xs">
                                    Currency:{" "}
                                    <span className="text-dark ms-sm-2 font-weight-bold">
                                      {item?.currency}
                                    </span>
                                  </span>
                                </div>
                                <div className="col">
                                  <span className="mb-2 text-xs">
                                    created At:{" "}
                                    <span className="text-dark font-weight-bold ms-sm-2">
                                      {Date(item.createdAt)}
                                    </span>
                                  </span>
                                  <br></br>
                                  <span className="mb-2 text-xs">
                                    updatedAt:{" "}
                                    <span className="text-dark ms-sm-2 font-weight-bold">
                                      {Date(item.updatedAt)}
                                    </span>
                                  </span>
                                  <br></br>
                                  <span className="text-xs">
                                    Payment_status:{" "}
                                    <span className="text-dark ms-sm-2 font-weight-bold">
                                      {item.payment_status}
                                    </span>
                                  </span>
                                </div>
                              </li>
                            </div>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
            <div className="fixed-plugin">
              <a className="fixed-plugin-button text-dark position-fixed px-3 py-2">
                <i className="fa fa-cog py-2"> </i>
              </a>
              <div className="card shadow-lg ">
                <div className="card-header pb-0 pt-3 ">
                  <div className="float-start">
                    <h5 className="mt-3 mb-0">Soft UI Configurator</h5>
                    <p>See our dashboard options.</p>
                  </div>
                  <div className="float-end mt-4">
                    <button className="btn btn-link text-dark p-0 fixed-plugin-close-button">
                      <i className="fa fa-close" />
                    </button>
                  </div>
                  {/* End Toggle Button */}
                </div>
                <hr className="horizontal dark my-1" />
                <div className="card-body pt-sm-3 pt-0">
                  {/* Sidebar Backgrounds */}
                  <div>
                    <h6 className="mb-0">Sidebar Colors</h6>
                  </div>
                  <a
                    href="javascript:void(0)"
                    className="switch-trigger background-color">
                    <div className="badge-colors my-2 text-start">
                      <span
                        className="badge filter bg-gradient-primary active"
                        data-color="primary"
                        onclick="sidebarColor(this)"
                      />
                      <span
                        className="badge filter bg-gradient-dark"
                        data-color="dark"
                        onclick="sidebarColor(this)"
                      />
                      <span
                        className="badge filter bg-gradient-info"
                        data-color="info"
                        onclick="sidebarColor(this)"
                      />
                      <span
                        className="badge filter bg-gradient-success"
                        data-color="success"
                        onclick="sidebarColor(this)"
                      />
                      <span
                        className="badge filter bg-gradient-warning"
                        data-color="warning"
                        onclick="sidebarColor(this)"
                      />
                      <span
                        className="badge filter bg-gradient-danger"
                        data-color="danger"
                        onclick="sidebarColor(this)"
                      />
                    </div>
                  </a>
                  {/* Sidenav Type */}
                  <div className="mt-3">
                    <h6 className="mb-0">Sidenav Type</h6>
                    <p className="text-sm">
                      Choose between 2 different sidenav types.
                    </p>
                  </div>
                  <div className="d-flex">
                    <button
                      className="btn bg-gradient-primary w-100 px-3 mb-2 active"
                      data-class="bg-transparent"
                      onclick="sidebarType(this)">
                      Transparent
                    </button>
                    <button
                      className="btn bg-gradient-primary w-100 px-3 mb-2 ms-2"
                      data-class="bg-white"
                      onclick="sidebarType(this)">
                      White
                    </button>
                  </div>
                  <p className="text-sm d-xl-none d-block mt-2">
                    You can change the sidenav type just on desktop view.
                  </p>
                  {/* Navbar Fixed */}
                  <div className="mt-3">
                    <h6 className="mb-0">Navbar Fixed</h6>
                  </div>
                  <div className="form-check form-switch ps-0">
                    <input
                      className="form-check-input mt-1 ms-auto"
                      type="checkbox"
                      id="navbarFixed"
                      onclick="navbarFixed(this)"
                    />
                  </div>
                  <hr className="horizontal dark my-sm-4" />
                  <a
                    className="btn bg-gradient-dark w-100"
                    href="https://www.creative-tim.com/product/soft-ui-dashboard">
                    Free Download
                  </a>
                  <a
                    className="btn btn-outline-dark w-100"
                    href="https://www.creative-tim.com/learning-lab/bootstrap/license/soft-ui-dashboard">
                    View documentation
                  </a>
                  <div className="w-100 text-center">
                    <a
                      className="github-button"
                      href="https://github.com/creativetimofficial/soft-ui-dashboard"
                      data-icon="octicon-star"
                      data-size="large"
                      data-show-count="true"
                      aria-label="Star creativetimofficial/soft-ui-dashboard on GitHub">
                      Star
                    </a>
                    <h6 className="mt-3">Thank you for sharing!</h6>
                    <a
                      href="https://twitter.com/intent/tweet?text=Check%20Soft%20UI%20Dashboard%20made%20by%20%40CreativeTim%20%23webdesign%20%23dashboard%20%23bootstrap5&url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fsoft-ui-dashboard"
                      className="btn btn-dark mb-0 me-2"
                      target="_blank">
                      <i className="fab fa-twitter me-1" aria-hidden="true" />{" "}
                      Tweet
                    </a>
                    <a
                      href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/soft-ui-dashboard"
                      className="btn btn-dark mb-0 me-2"
                      target="_blank">
                      <i
                        className="fab fa-facebook-square me-1"
                        aria-hidden="true"
                      />{" "}
                      Share
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/*   Core JS Files   */}
            {/* Github buttons */}
            {/* Control Center for Soft Dashboard: parallax effects, scripts for the example pages etc */}
          </>

          <div className="fixed-plugin">
            <a className="fixed-plugin-button text-dark position-fixed px-3 py-2">
              <i className="fa fa-cog py-2"> </i>
            </a>
            <div className="card shadow-lg ">
              <div className="card-header pb-0 pt-3 ">
                <div className="float-start">
                  <h5 className="mt-3 mb-0">Soft UI Configurator</h5>
                  <p>See our dashboard options.</p>
                </div>
                <div className="float-end mt-4">
                  <button className="btn btn-link text-dark p-0 fixed-plugin-close-button">
                    <i className="fa fa-close" />
                  </button>
                </div>
                {/* End Toggle Button */}
              </div>
              <hr className="horizontal dark my-1" />
              <div className="card-body pt-sm-3 pt-0">
                {/* Sidebar Backgrounds */}
                <div>
                  <h6 className="mb-0">Sidebar Colors</h6>
                </div>
                <a
                  href="javascript:void(0)"
                  className="switch-trigger background-color">
                  <div className="badge-colors my-2 text-start">
                    <span
                      className="badge filter bg-gradient-primary active"
                      data-color="primary"
                      onclick="sidebarColor(this)"
                    />
                    <span
                      className="badge filter bg-gradient-dark"
                      data-color="dark"
                      onclick="sidebarColor(this)"
                    />
                    <span
                      className="badge filter bg-gradient-info"
                      data-color="info"
                      onclick="sidebarColor(this)"
                    />
                    <span
                      className="badge filter bg-gradient-success"
                      data-color="success"
                      onclick="sidebarColor(this)"
                    />
                    <span
                      className="badge filter bg-gradient-warning"
                      data-color="warning"
                      onclick="sidebarColor(this)"
                    />
                    <span
                      className="badge filter bg-gradient-danger"
                      data-color="danger"
                      onclick="sidebarColor(this)"
                    />
                  </div>
                </a>
                {/* Sidenav Type */}
                <div className="mt-3">
                  <h6 className="mb-0">Sidenav Type</h6>
                  <p className="text-sm">
                    Choose between 2 different sidenav types.
                  </p>
                </div>
                <div className="d-flex">
                  <button
                    className="btn bg-gradient-primary w-100 px-3 mb-2 active"
                    data-class="bg-transparent"
                    onclick="sidebarType(this)">
                    Transparent
                  </button>
                  <button
                    className="btn bg-gradient-primary w-100 px-3 mb-2 ms-2"
                    data-class="bg-white"
                    onclick="sidebarType(this)">
                    White
                  </button>
                </div>
                <p className="text-sm d-xl-none d-block mt-2">
                  You can change the sidenav type just on desktop view.
                </p>
                {/* Navbar Fixed */}
                <div className="mt-3">
                  <h6 className="mb-0">Navbar Fixed</h6>
                </div>
                <div className="form-check form-switch ps-0">
                  <input
                    className="form-check-input mt-1 ms-auto"
                    type="checkbox"
                    id="navbarFixed"
                    onclick="navbarFixed(this)"
                  />
                </div>
                <hr className="horizontal dark my-sm-4" />
                <a
                  className="btn bg-gradient-dark w-100"
                  href="https://www.creative-tim.com/product/soft-ui-dashboard">
                  Free Download
                </a>
                <a
                  className="btn btn-outline-dark w-100"
                  href="https://www.creative-tim.com/learning-lab/bootstrap/license/soft-ui-dashboard">
                  View documentation
                </a>
                <div className="w-100 text-center">
                  <a
                    className="github-button"
                    href="https://github.com/creativetimofficial/soft-ui-dashboard"
                    data-icon="octicon-star"
                    data-size="large"
                    data-show-count="true"
                    aria-label="Star creativetimofficial/soft-ui-dashboard on GitHub">
                    Star
                  </a>
                  <h6 className="mt-3">Thank you for sharing!</h6>
                  <a
                    href="https://twitter.com/intent/tweet?text=Check%20Soft%20UI%20Dashboard%20made%20by%20%40CreativeTim%20%23webdesign%20%23dashboard%20%23bootstrap5&url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fsoft-ui-dashboard"
                    className="btn btn-dark mb-0 me-2"
                    target="_blank">
                    <i className="fab fa-twitter me-1" aria-hidden="true" />{" "}
                    Tweet
                  </a>
                  <a
                    href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/soft-ui-dashboard"
                    className="btn btn-dark mb-0 me-2"
                    target="_blank">
                    <i
                      className="fab fa-facebook-square me-1"
                      aria-hidden="true"
                    />{" "}
                    Share
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/*   Core JS Files   */}
          {/* Github buttons */}
          {/* Control Center for Soft Dashboard: parallax effects, scripts for the example pages etc */}
        </div>
      </>
    </div>
  );
};
export default Profile;
