import React from "react";
import { useState } from "react";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import useFetch from "../hooks/useFetch";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../src/redux/cartSlice";
import Carousel from "react-bootstrap/Carousel";

import { currencyData, ExchangeData } from "../currency";

const Product = () => {
  const currency = currencyData();
  const Rate = ExchangeData();
  const Z = currency == "ETB" ? 1 : Rate;

  const id = useParams().id;
  const [selectedImg, setSelectedImg] = useState("img");
  const [quantity, setQuantity] = useState(1);
  const [index, setIndex] = useState(1);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  const dispatch = useDispatch();
  const { data, loading, error } = useFetch(`/products/${id}?populate=*`);

  //  console.log("data", data?.attributes?.merchants);

  const [carouselActive1, setcarouselActive1] = useState(true);
  const [carouselActive2, setcarouselActive2] = useState(false);
  const [carouselActive3, setcarouselActive3] = useState(false);
  const [carouselActive4, setcarouselActive4] = useState(false);

  // console.log(carouselActive1,'carouselActive1')
  // console.log(carouselActive2,'carouselActive2')
  // console.log(carouselActive3,'carouselActive3')
  // console.log(carouselActive4,'carouselActive4')

  return (
    <div>
      {/*} ##### Main Content Wrapper Start ##### */}
      <div
        class="main-content-wrapper d-flex clearfix "
        style={{ marginLeft: "3rem",marginBottom:'10rem' }}>
        {/*} Product Details Area Start */}
        <div class="single-product-area section-padding-5 clearfix ">
          <div class="container-fluid ">
            <div>
              <div>
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb mt-50">
                    <li class="breadcrumb-item">
                      <a>Home</a>
                    </li>

                    <li class="breadcrumb-item">
                      <a>
                        {" "}
                        {data?.attributes?.category?.data?.attributes?.title}
                      </a>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {data?.attributes?.title}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>

            <div class="row">
              <div class="col-12 col-lg-12">
                <div class="single_product_thumb">
                  <div
                    id="product_details_slider"
                    class="carousel slide"
                    data-ride="carousel">
                    <ol class="carousel-indicators">
                      <li
                        class={carouselActive1 == true ? "active" : ""}
                        onClick={(e) => [
                          setcarouselActive1(true),
                          setcarouselActive2(false),
                          setcarouselActive3(false),
                          setcarouselActive4(false),
                        ]}
                        data-target="#product_details_slider"
                        data-slide-to="1"
                        style={{
                          backgroundImage: `url(${
                            process.env.REACT_APP_UPLOAD_URL +
                            data?.attributes?.gallery?.data[0].attributes.url
                          })`,
                        }}></li>
                      <li
                        class={carouselActive2 == true ? "active" : ""}
                        onClick={(e) => [
                          setcarouselActive1(false),
                          setcarouselActive2(true),
                          setcarouselActive3(false),
                          setcarouselActive4(false),
                        ]}
                        data-target="#product_details_slider"
                        data-slide-to="2"
                        style={{
                          backgroundImage: `url(${
                            process.env.REACT_APP_UPLOAD_URL +
                            data?.attributes?.gallery?.data[1]?.attributes?.url
                          })`,
                        }}></li>
                      <li
                        class={carouselActive3 == true ? "active" : ""}
                        onClick={(e) => [
                          setcarouselActive1(false),
                          setcarouselActive2(false),
                          setcarouselActive3(true),
                          setcarouselActive4(false),
                        ]}
                        data-target="#product_details_slider"
                        data-slide-to="3"
                        style={{
                          backgroundImage: `url(${
                            process.env.REACT_APP_UPLOAD_URL +
                            data?.attributes?.gallery?.data[2]?.attributes?.url
                          })`,
                        }}></li>
                      <li
                        class={carouselActive4 == true ? "active" : ""}
                        onClick={(e) => [
                          setcarouselActive1(false),
                          setcarouselActive2(false),
                          setcarouselActive3(false),
                          setcarouselActive4(true),
                        ]}
                        data-target="#product_details_slider"
                        data-slide-to="4"
                        style={{
                          backgroundImage: `url(${
                            process.env.REACT_APP_UPLOAD_URL +
                            data?.attributes?.gallery?.data[3]?.attributes?.url
                          })`,
                        }}></li>
                    </ol>
                    <div class="carousel-inner  ">
                      <div
                        class={
                          carouselActive1 == true
                            ? "carousel-item  active"
                            : "carousel-item"
                        }>
                        <a
                          class="gallery_img"
                          href={
                            process.env.REACT_APP_UPLOAD_URL +
                            data?.attributes?.gallery?.data[0].attributes.url
                          }>
                          <img
                            class="d-block w-50"
                            style={{ overflow: "hidden" }}
                            src={
                              process.env.REACT_APP_UPLOAD_URL +
                              data?.attributes?.gallery?.data[0].attributes.url
                            }
                            alt="First slide"
                          />
                        </a>
                      </div>
                      <div
                        class={
                          carouselActive2 == true
                            ? "carousel-item  active"
                            : "carousel-item"
                        }>
                        <a
                          class="gallery_img"
                          href={
                            process.env.REACT_APP_UPLOAD_URL +
                            data?.attributes?.gallery?.data[1]?.attributes?.url
                          }>
                          <img
                            class="d-block w-50"
                            style={{ overflow: "hidden" }}
                            src={
                              process.env.REACT_APP_UPLOAD_URL +
                              data?.attributes?.gallery?.data[1]?.attributes
                                ?.url
                            }
                            alt="Second slide"
                          />
                        </a>
                      </div>
                      <div
                        class={
                          carouselActive3 == true
                            ? "carousel-item  active"
                            : "carousel-item"
                        }>
                        <a
                          class="gallery_img"
                          href={
                            process.env.REACT_APP_UPLOAD_URL +
                            data?.attributes?.gallery?.data[2]?.attributes?.url
                          }>
                          <img
                            class="d-block w-50"
                            style={{ overflow: "hidden" }}
                            src={
                              process.env.REACT_APP_UPLOAD_URL +
                              data?.attributes?.gallery?.data[2]?.attributes
                                ?.url
                            }
                            alt="Third slide"
                          />
                        </a>
                      </div>
                      <div
                        class={
                          carouselActive4 == true
                            ? "carousel-item  active"
                            : "carousel-item"
                        }>
                        <a
                          class="gallery_img"
                          href={
                            process.env.REACT_APP_UPLOAD_URL +
                            data?.attributes?.gallery?.data[3]?.attributes?.url
                          }>
                          <img
                            class="d-block w-50"
                            style={{ overflow: "hidden" }}
                            src={
                              process.env.REACT_APP_UPLOAD_URL +
                              data?.attributes?.gallery?.data[3]?.attributes
                                ?.url
                            }
                            alt="Fourth slide"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-12">
                <div class="single_product_desc">
                  {/*} Product Meta Data */}
                  <div class="product-meta-data">
                    <div class="line"></div>
                    <p class="product-price">
                      {" "}
                      {(data?.attributes.price * Z).toFixed(2)}{" "}
                      {currency == "ETB" ? " ETB" : " USD"}
                    </p>
                    <a href="product-details.html">
                      <h6> {data?.attributes.title}</h6>
                    </a>
                    {/*} Ratings & Review */}
                    <div class="ratings-review mb-15 d-flex align-items-center justify-content-between">
                      <div class="ratings">
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                      </div>
                      {/* <div class="review">
                        <a href="#">Write A Review</a>
                      </div> */}
                    </div>
                    {/*} Avaiable */}
                    {data?.attributes?.status == "In-Stock" ? (
                      <p class="avaibility">
                        <i class="fa fa-circle"></i> In Stock
                      </p>
                    ) : (
                      <p class="avaibility">
                        <i class="fa fa-circle" style={{ color: "red" }}></i>{" "}
                        <>Out Of Stock</>
                      </p>
                    )}
                  </div>

                  <div class="short_overview my-5">
                    <p>{data?.attributes?.desc}</p>
                  </div>

                  {/*} Add to Cart Form */}
                  <form class="cart clearfix" method="post">
                    <div class="cart-btn d-flex mb-50">
                      <p>Qty</p>
                      <div class="quantity">
                        <span
                          class="qty-minus"
                          onClick={() =>
                            setQuantity((prev) => (prev === 1 ? 1 : prev - 1))
                          }>
                          <i class="fa fa-caret-down" aria-hidden="true"></i>
                        </span>
                        <input
                          type="number"
                          class="qty-text"
                          id="qty"
                          step="1"
                          min="1"
                          max="300"
                          name="quantity"
                          value={quantity}
                        />
                        <span
                          class="qty-plus"
                          onClick={() => setQuantity((prev) => prev + 1)}>
                          <i class="fa fa-caret-up" aria-hidden="true"></i>
                        </span>
                      </div>
                    </div>
                    {data?.attributes?.status == "In-Stock" ? (
                      <button
                        type="button"
                        name="addtocart"
                        value="5"
                        class="btn bg-gradient-primary"
                        onClick={() =>
                          dispatch(
                            addToCart({
                              id: data.id,
                              title: data.attributes.title,
                              price: data.attributes.price,
                              Color: data.attributes.Color,
                              Size: data.attributes.Size,
                              category: data.attributes.category,
                              desc: data.attributes.desc,
                              gallery: data.attributes.gallery,
                              info: data.attributes.info,
                              review: data.attributes.review,
                              currency: currency,
                              quantity,
                            })
                          )
                        }>
                        Add to cart
                      </button>
                    ) : (
                      <></>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*} Product Details Area End */}
      </div>
      {/*} ##### Main Content Wrapper End ##### */}
    </div>
  );
};
export default Product;
