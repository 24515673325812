import React, { useState, useEffect } from "react";
// read store value
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import {
  incrementQuantity,
  decrementQuantity,
  removeItem,
} from "../../src/redux/cartSlice";
// get value of cart from storage
import { currencyData, ExchangeData } from "../currency";

const Cart = () => {
  const currency = currencyData();
  const Rate = ExchangeData();
  const Z = currency == "ETB" ? 1 : Rate;

  const Cart_Products = useSelector((state) => state.Cart_Products);
  const dispatch = useDispatch();
  const final_pay = Cart_Products.reduce(
    (total, item) => total + item.price * item.quantity * Z,
    0
  );

  const Delivery = 0;

  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const successHandler = (position) => {
      const { latitude, longitude } = position.coords;
      setLocation({ latitude, longitude });
    };

    const errorHandler = (error) => {
      setError(error.message);
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(successHandler, errorHandler);
    } else {
      setError("Geolocation is not supported by your browser");
    }
  }, []); // Empty dependency array to run the effect only once when the component mounts
  // console.log('final_pay',final_pay)
  /* 	console.log(Cart_Products,'Cart_Products') */
  return (
    <div class="js " style={{ marginTop: "20px" }}>
      <div className="col-lg-12">
        <div class="cart-table-area cartmarigin ">
          <div class=" ">
            <div class="row">
              <div className="cartmobile" style={{backgroundColor:'white',padding:'4px',marginBottom:'40px',borderRadius:'10px',boxShadow:" rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
              {Cart_Products.map((item) => (
                <div key={item.id} className="row"  style={{ padding:'4px',margin:'5px',borderRadius:'10px' }} >
                  <div className="col-6">


                              <img
                                src={
                                  process.env.REACT_APP_UPLOAD_URL +
                                  item.gallery?.data[0].attributes?.url
                                }
                                alt="Product"
                              style={{width:'200px',borderRadius:'10px'}}
                              />

                  </div>
                  <div className="col-6" style={{display:'grid'}}>
                  <div class="cart_product_desc" style={{textAlign:'left',verticalAlign:'center',justifyContent:'center'}} >
                            <Link to={`/product/${item.id}`}>
                              <h3 style={{ fontSize: "18px" }}>{item.title}</h3>
                            </Link>
                          </div>
                          <div class="price">
                            <span>
                              {(item.price * Z).toFixed(2)}
                              {currency == "ETB" ? " ETB" : " USD"}
                            </span>
                          </div>
                          <div class="qty">
                            <div class="qty-btn d-flex  ">
                              <div class="quantity row ">
                                <div
                                  class="qty-minus col-4 "
                                  style={{ paddingRight: "1.5rem" }}
                                  onClick={() =>
                                    dispatch(decrementQuantity(item.id))
                                  }>
                                  <i
                                    class="fa fa-minus "
                                    aria-hidden="true"></i>
                                </div>

                                {item.quantity}

                                <div
                                  class="qty-plus col-4"
                                  style={{ paddingRight: "1.5rem" }}
                                  onClick={() =>
                                    dispatch(incrementQuantity(item.id))
                                  }>
                                  <i class="fa fa-plus" aria-hidden="true"></i>
                                </div>
                              </div>{" "}
                              <div
                                className="col-4"
                                style={{ paddingLeft: "1.5rem" }}
                                onClick={() => dispatch(removeItem(item.id))}>
                                <i class="fa fa-trash" aria-hidden="true"></i>
                              </div>
                            </div>
                          </div>
</div>

                        </div>
                      ))}
    </div>


              <div class="col-md-9 col-lg-9   ">



                <div class="cart-table clearfix newcard carthide">
                  <table class="table table-responsive ">
                    <thead className="   ">
                      <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Cart_Products.map((item) => (
                        <tr key={item.id}>
                          <td class="cart_product_img">
                            <a href="#">
                              <img
                                src={
                                  process.env.REACT_APP_UPLOAD_URL +
                                  item.gallery?.data[0].attributes?.url
                                }
                                alt="Product"
                                width={150}
                              />
                            </a>
                          </td>
                          <td class="cart_product_desc">
                            <Link to={`/product/${item.id}`}>
                              <h5 style={{ fontSize: "14px" }}>{item.title}</h5>
                            </Link>
                          </td>
                          <td class="price">
                            <span>
                              {(item.price * Z).toFixed(2)}
                              {currency == "ETB" ? " ETB" : " USD"}
                            </span>
                          </td>
                          <td class="qty">
                            <div class="qty-btn d-flex  ">
                              <div class="quantity row ">
                                <div
                                  class="qty-minus col-4 "
                                  style={{ paddingRight: "1.5rem" }}
                                  onClick={() =>
                                    dispatch(decrementQuantity(item.id))
                                  }>
                                  <i
                                    class="fa fa-minus "
                                    aria-hidden="true"></i>
                                </div>

                                {item.quantity}

                                <div
                                  class="qty-plus col-4"
                                  style={{ paddingRight: "1.5rem" }}
                                  onClick={() =>
                                    dispatch(incrementQuantity(item.id))
                                  }>
                                  <i class="fa fa-plus" aria-hidden="true"></i>
                                </div>
                              </div>{" "}
                              <div
                                className="col-4"
                                style={{ paddingLeft: "1.5rem" }}
                                onClick={() => dispatch(removeItem(item.id))}>
                                <i class="fa fa-trash" aria-hidden="true"></i>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-md-3 col-lg-3 newcard ">
                <div class="cart-summary " style={{ padding: "1.5rem" }}>
                  <h5>Cart Total</h5>
                  <ul class="summary-table ">
                    <li>
                      <span>subtotal:</span>{" "}
                      <span>
                        {" "}
                        {Cart_Products.reduce(
                          (total, item) =>
                            total + item.price * Z * item.quantity,
                          0
                        ).toFixed(2)}
                        {currency == "ETB" ? " ETB" : " USD"}
                      </span>
                    </li>
                    <li>
                      <span>delivery:</span>{" "}
                      <span>
                        {" "}
                        {Delivery.toFixed(2)}
                        {currency == "ETB" ? " ETB" : " USD"}
                      </span>
                    </li>
                    <li>
                      <span>total:</span>{" "}
                      <span>
                        {" "}
                        {final_pay.toFixed(2)}{" "}
                        {currency == "ETB" ? " ETB" : " USD"}
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="cart-btn mt-30">
                  <a href="/checkout" class="btn  bg-gradient-primary w-100">
                    <small>Checkout</small>
                  </a>
                </div>
                <div class="cart-btn mt-30">
                  <a href="/shop" class="btn  bg-gradient-primary w-100">
                    <small>Continue Shopping</small>
                  </a>
                </div>
              </div>

              <div className="col-md-12">
                <div className="row">
                  {/* {location ? (
                  <div>
                    <p>Latitude: {location.latitude}</p>
                    <p>Longitude: {location.longitude}</p>
                  </div>
                ) : (
                  <p>{error || "Loading location..."}</p>
                )} */}
                  {/* <div className="col-12">
                  <div style={{ marginLeft: "50px" }}>
                    <h6 style={{ marginLeft: "-20px" }}>
                      Choose Delivery Options
                    </h6>
                    {currency == "ETB" ? (
                      <ul
                        className="check-box-list"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}>
                        <li style={{ padding: "10px" }}>
                          <label className="checkbox-inline" for="self">
                            <input
                              name="delivery"
                              id="self"
                              type="radio"
                              value="self"
                              checked
                            />

                            <p>Self Pick</p>
                          </label>
                        </li>

                        <li style={{ padding: "10px" }}>
                          <label className="checkbox-inline" for="p1">
                            <input
                              name="delivery"
                              id="p1"
                              type="radio"
                              value="feres"
                              disabled
                            />
                            <img
                              src={feres}
                              style={{
                                width: "60px",
                                borderRadius: "10px",
                                marginLeft: "5px",
                              }}></img>
                          </label>
                        </li>

                        <li style={{ padding: "10px" }}>
                          <label className="checkbox-inline" for="p2">
                            <input
                              name="delivery"
                              id="p2"
                              type="radio"
                              value="eshi"
                              disabled
                            />
                            <img
                              src={eshi}
                              style={{
                                width: "60px",
                                borderRadius: "10px",
                                marginLeft: "5px",
                              }}></img>
                          </label>
                        </li>
                        <li style={{ padding: "10px" }}>
                          <label className="checkbox-inline" for="p3">
                            <input
                              name="delivery"
                              id="p3"
                              type="radio"
                              disabled
                              value="zmall"></input>
                            <img
                              src={zmall}
                              style={{
                                width: "60px",
                                borderRadius: "10px",
                                marginLeft: "5px",
                              }}></img>
                          </label>
                        </li>
                      </ul>
                    ) : (
                      <ul
                        className="check-box-list"
                        style={{ display: "flex", flexDirection: "row" }}>
                        <li style={{ padding: "10px" }}>
                          <label className="checkbox-inline" for="export">
                            <input
                              name="delivery"
                              id="export"
                              type="radio"
                              value="dhl"
                              disabled
                            />
                            <img
                              src={dhl}
                              style={{
                                width: "100px",
                                borderRadius: "10px",
                                marginLeft: "5px",
                              }}></img>
                          </label>
                        </li>
                      </ul>
                    )}
                  </div>
                </div> */}
                  <div
                    className="col-12"
                    style={{
                      textDecoration: "none",
                      overflow: "hidden",
                      maxWidth: "100%",
                      width: "300px",
                      height: "300px",
                    }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Cart;
