 import React from 'react'

const About = () => {
  return (
    <div>
      <div>About</div>
    </div>
  )
}

export default About

