import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { usePDF } from 'react-to-pdf';
const Verify =  ( ) => {
  const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});
  const [file, setFile] = useState('null');

    const [error,setError] = useState();
    const tx_ref = useParams().tx_ref;

    const verify_payments = async () => {
      const url = process.env.REACT_APP_API_URL + `/verify`;
      const datas = {
        tx_ref: tx_ref,
      };
      // console.log(datas);
      try {
        await axios
          .post(url, datas, {
            headers: {
              Authorization: "bearer " + process.env.REACT_APP_API_TOKEN,
            },
          })
          .then((response) => setFile(response?.data));
      } catch (error) {
        setError(error.response?.data);
        //  console.log(error.response.data,'error');
      }
    };

     useEffect(() => {
      verify_payments();
     }, []);

     console.log(file ,"file");
    //  console.log(file,'file')
    //  console.log(error,'the error response')
    try {
      const URL = process.env.REACT_APP_API_URL + `/updatestatus`;
      const datas = {
        tx_ref: tx_ref,
      };
      if (file?.status == "success") {
        axios.post(URL, datas, {
          headers: {
            Authorization: "bearer " + process.env.REACT_APP_API_TOKEN,
          },
        });
      }
    } catch (error) {
      // console.log(error)
    }

    return (
      <div>
        <div className="container " style={{ margin: "11rem" }}>
          <div className="row" ref={targetRef}>
            <div className="col-md-4">
              {/* {
        returndata()
      } */}

              {file?.status == "success" ? (
                <div className="alert alert-success " role="alert">
                  <div
                    style={{
                      color:'white',
                      fontWeight: "bold",
                      fontSize: "1.6rem",
                      padding: "1rem",
                      textTransform: "uppercase",
                    }}>
                    Payment Succeed
                  </div>
                  <div
                    style={{     color:'white',
                      fontWeight: "300",
                      fontSize: "1.2rem",
                      padding: "1rem",    textTransform: "uppercase",
                    }}>
                    message: {file.message}
                  </div>
                  <div
                    style={{     color:'white',
                      fontWeight: "300",
                      fontSize: "1.2rem",
                      padding: "1rem",    textTransform: "uppercase",
                    }}>
                    status : {file.status}
                  </div>
                  <div
                    style={{     color:'white',
                      fontWeight: "300",
                      fontSize: "1.2rem",
                      padding: "1rem",    textTransform: "uppercase",
                    }}>
                    method : {file?.data?.method}
                  </div>
                  <div
                    style={{     color:'white',
                      fontWeight: "300",
                      fontSize: "1.2rem",
                      padding: "1rem",    textTransform: "uppercase",
                    }}>
                    first name : {file.data?.first_name}
                  </div>
                  <div
                    style={{     color:'white',
                      fontWeight: "300",
                      fontSize: "1.2rem",
                      padding: "1rem",    textTransform: "uppercase",
                    }}>
                    last name : {file.data?.last_name}
                  </div>
                  <div
                    style={{     color:'white',
                      fontWeight: "300",
                      fontSize: "1.2rem",
                      padding: "1rem",    textTransform: "uppercase",
                    }}>
                    email : {file.data?.email}
                  </div>
                  <div
                    style={{     color:'white',
                      fontWeight: "300",
                      fontSize: "1.2rem",    textTransform: "uppercase",
                      padding: "1rem",
                    }}>
                    amount : {file.data?.amount}
                  </div>
                  <div
                    style={{     color:'white',
                      fontWeight: "300",
                      fontSize: "1.2rem",    textTransform: "uppercase",
                      padding: "1rem",
                    }}>
                    {" "}
                    reference : {file.data?.reference}
                  </div>
                  <div
                    style={{     color:'white',
                      fontWeight: "300",
                      fontSize: "1.2rem",
                      padding: "1rem",    textTransform: "uppercase",
                    }}>
                    {" "}
                    tx_ref : {file.data?.tx_ref}
                  </div>


                  <div
                    style={{     color:'white',
                      fontWeight: "300",
                      fontSize: "1rem",
                      padding: "1rem",    textTransform: "uppercase",
                    }}>
                    created_at : {file.data?.created_at}
                  </div>
                </div>
              ) : (
                <div className="alert alert-danger " role="alert">
                  <div
                    style={{     color:'white',
                      fontWeight: "bold",
                      fontSize: "1.6rem",
                      padding: "1rem",
                      textTransform: "uppercase",
                    }}>
                    Payment Failed
                  </div>
                  <div
                    style={{     color:'white',
                      fontWeight: "300",
                      fontSize: "1.2rem", textTransform: "uppercase",
                      padding: "1rem",
                    }}>
                    message: {file?.message}
                  </div>
                  <div
                    style={{     color:'white',
                      fontWeight: "300",
                      fontSize: "1.2rem",
                      padding: "1rem", textTransform: "uppercase",
                    }}>
                    status : {file?.status}
                  </div>
                </div>
              )}
            </div>
          </div>
          <button onClick={() => toPDF()} class="btn btn-primary">
            Download{" "}
          </button>
        </div>
      </div>
    );
}
export default Verify