import React, { Component } from "react";
import { Link } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { makeRequest } from "../MakeReq/makeRequest";
import { currencyData, ExchangeData } from "../currency";
const Dealoftheday = () => {
  const currency = currencyData();
  const Rate = ExchangeData();
  const Z = currency == "ETB" ? 1 : Rate;
  const { data, loading, error } = useFetch(
    `/products?populate=*&[filters][deal_of_the_day][$eq]='true'`
  );
  console.log(data?.slice(0, 1), "dealoftheday");
  const deal = data?.slice(0, 1);
  return (
    <div style={{ marginBottom: "3rem" }}>
      <section  >
        <div class="section-inner ">
          <div >
            <div style={{ padding: "1rem" }}>
              {data?.slice(0, 1)?.map((x) => (
                <div className="row" >
                  <div class="col-12 col-lg-4 padding-right">
                    <div class="image">
                      <img
                        src={
                          process.env.REACT_APP_UPLOAD_URL +
                          x?.attributes?.gallery?.data[0].attributes?.url
                        }
                        alt="#"
                      />
                    </div>
                  </div>
                  <div class=" col-12 col-lg-8 padding-left " style={{    display: "flex",flexDirection:'column', backgroundColor:'lightgray',
                     verticalAlign:'center',textAlign:'center',padding:'100px'}}>
                  <h1>Deal of day</h1>
                        <h3 class="title">{x?.attributes?.title}</h3>
                        <p class="text">{x.attributes?.desc.slice(0, 200)}</p>
                        <h1 >
                          {(
                            x.attributes?.price * Z -
                            x.attributes?.discount * Z
                          ).toFixed(2)}
                          {currency == "ETB" ? " ETB" : " USD"}{""}<span>   </span>
                          <s style={{color:'darkgrey',fontWeight:'normal'}}>
                            {(x.attributes?.price * Z).toFixed(2)}
                            {currency == "ETB" ? " ETB" : " USD"}
                          </s>
                        </h1>
                        <div class="coming-time">
                          <div
                            class="clearfix"
                            data-countdown="2021/02/30"></div>
                        </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dealoftheday;
