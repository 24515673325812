import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import Signin from "../pages/Signin";
import axios from "axios";
import { storeUser } from "../helper";
// import { Form, Button } from "semantic-ui-react";
import { useForm } from "react-hook-form";
// impo rt "./ss.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
const intialUser = { password: "", email: "", username: "" };

const Signup = () => {
  const [user, SetUser] = useState({
    username: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    password: "",
  });
  const handle = ({ target: { value } }) => SetUser(value);
  // console.log(user,'user')

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required("User name is required")
      .min(3, "User name must be minimum 6 characters"),

    first_name: Yup.string()
      .required("first_name is required")
      .min(3, "First Name must be minimum 6 characters"),

    last_name: Yup.string()
      .required("last_name is required")
      .min(3, "Last Name must be minimum 6 characters"),

    phone_number: Yup.string()
      .required("Phone Number is required")
      .min(7, "Phone Number is not valid")
      .max(13, "Phone Number is not valid"),

    email: Yup.string().required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .min(6, "Password must be at least 6 characters"),
    confirmPassword: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password")], "Passwords must match"),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;
  // console.log('register',register)
  const onSubmit = async (aa) => {
    // display form aa on success
    SetUser(aa);
    // console.log('aa',aa)
    const url = process.env.REACT_APP_API_URL + `/auth/local/register`;

    try {
      if (aa.email && aa.password && aa.username) {
        const { data } = await axios.post(url, aa, {
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: "bearer " + process.env.REACT_APP_API_TOKEN,
          },
        });

        /*   console.log(data,'data'); */
        if (data.jwt) {
          storeUser(data);
          SetUser(intialUser);
          navigate("/Signin");
        }
      }
    } catch (error) {
      //  console.log(error,'error');
    }
  };

  return (
    <div>
      <>
        <main className="main-content  mt-0">
          <section className="min-vh-100 mb-8">
            <div
              className="page-header align-items-start min-vh-50 pt-5 pb-11 m-3 border-radius-lg"
              style={{
                backgroundImage:
                  'url("../assets/img/curved-images/curved14.jpg")',
              }}>
              <span className="mask bg-gradient-dark opacity-6" />
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-5 text-center mx-auto">
                    <h1 className="text-white mb-2 mt-5">Welcome!</h1>
                    <p className="text-lead text-white">
                      Use these awesome forms to login or create new account in
                      your project for free.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row mt-lg-n10 mt-md-n11 mt-n10">
                <div className="col-xl-5 col-lg-5 col-md-7 mx-auto">
                  <div className="card z-index-0">
                    <div className="card-header text-center pt-4">
                      <h5>Register with</h5>
                    </div>
                    {/* <div className="row px-xl-5 px-sm-4 px-3">
                      <div className="col-4 ms-auto px-1">
                        <a
                          className="btn btn-outline-light w-100"
                          href="javascript:;">
                          <svg
                            width="24px"
                            height="32px"
                            viewBox="0 0 64 64"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink32">
                            <g
                              id="Artboard"
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd">
                              <g
                                id="facebook-3"
                                transform="translate(3.000000, 3.000000)"
                                fillRule="nonzero">
                                <circle
                                  fill="#3C5A9A"
                                  cx="29.5091719"
                                  cy="29.4927506"
                                  r="29.4882047"
                                />
                                <path
                                  d="M39.0974944,9.05587273 L32.5651312,9.05587273 C28.6886088,9.05587273 24.3768224,10.6862851 24.3768224,16.3054653 C24.395747,18.2634019 24.3768224,20.1385313 24.3768224,22.2488655 L19.8922122,22.2488655 L19.8922122,29.3852113 L24.5156022,29.3852113 L24.5156022,49.9295284 L33.0113092,49.9295284 L33.0113092,29.2496356 L38.6187742,29.2496356 L39.1261316,22.2288395 L32.8649196,22.2288395 C32.8649196,22.2288395 32.8789377,19.1056932 32.8649196,18.1987181 C32.8649196,15.9781412 35.1755132,16.1053059 35.3144932,16.1053059 C36.4140178,16.1053059 38.5518876,16.1085101 39.1006986,16.1053059 L39.1006986,9.05587273 L39.0974944,9.05587273 L39.0974944,9.05587273 Z"
                                  id="Path"
                                  fill="#FFFFFF"
                                />
                              </g>
                            </g>
                          </svg>
                        </a>
                      </div>

                      <div className="col-3 me-auto px-1">
                        <a
                          className="btn btn-outline-light w-100"
                          href="javascript:;">
                          <svg
                            width="24px"
                            height="32px"
                            viewBox="0 0 64 64"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink">
                            <g
                              id="Artboard"
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd">
                              <g
                                id="google-icon"
                                transform="translate(3.000000, 2.000000)"
                                fillRule="nonzero">
                                <path
                                  d="M57.8123233,30.1515267 C57.8123233,27.7263183 57.6155321,25.9565533 57.1896408,24.1212666 L29.4960833,24.1212666 L29.4960833,35.0674653 L45.7515771,35.0674653 C45.4239683,37.7877475 43.6542033,41.8844383 39.7213169,44.6372555 L39.6661883,45.0037254 L48.4223791,51.7870338 L49.0290201,51.8475849 C54.6004021,46.7020943 57.8123233,39.1313952 57.8123233,30.1515267"
                                  id="Path"
                                  fill="#4285F4"
                                />
                                <path
                                  d="M29.4960833,58.9921667 C37.4599129,58.9921667 44.1456164,56.3701671 49.0290201,51.8475849 L39.7213169,44.6372555 C37.2305867,46.3742596 33.887622,47.5868638 29.4960833,47.5868638 C21.6960582,47.5868638 15.0758763,42.4415991 12.7159637,35.3297782 L12.3700541,35.3591501 L3.26524241,42.4054492 L3.14617358,42.736447 C7.9965904,52.3717589 17.959737,58.9921667 29.4960833,58.9921667"
                                  id="Path"
                                  fill="#34A853"
                                />
                                <path
                                  d="M12.7159637,35.3297782 C12.0932812,33.4944915 11.7329116,31.5279353 11.7329116,29.4960833 C11.7329116,27.4640054 12.0932812,25.4976752 12.6832029,23.6623884 L12.6667095,23.2715173 L3.44779955,16.1120237 L3.14617358,16.2554937 C1.14708246,20.2539019 0,24.7439491 0,29.4960833 C0,34.2482175 1.14708246,38.7380388 3.14617358,42.736447 L12.7159637,35.3297782"
                                  id="Path"
                                  fill="#FBBC05"
                                />
                                <path
                                  d="M29.4960833,11.4050769 C35.0347044,11.4050769 38.7707997,13.7975244 40.9011602,15.7968415 L49.2255853,7.66898166 C44.1130815,2.91684746 37.4599129,0 29.4960833,0 C17.959737,0 7.9965904,6.62018183 3.14617358,16.2554937 L12.6832029,23.6623884 C15.0758763,16.5505675 21.6960582,11.4050769 29.4960833,11.4050769"
                                  id="Path"
                                  fill="#EB4335"
                                />
                              </g>
                            </g>
                          </svg>
                        </a>
                      </div>
                      <div className="mt-2 position-relative text-center">
                        <p className="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3">
                          or
                        </p>
                      </div>
                    </div> */}
                    <div className="card-body ">
                      <form role="form " onSubmit={handleSubmit(onSubmit)}>
                        <div className="mb-3 ">
                          <input
                            placeholder="User Name"
                            aria-label="Name"
                            aria-describedby="email-addon"
                            name="username"
                            type="text"
                            {...register("username")}
                            className={`form-control newcard2${
                              errors.username ? "is-invalid" : ""
                            }`}
                          />
                          <div className="invalid-feedback">
                            {errors.username?.message}
                          </div>
                        </div>
                        <div className="mb-3 ">
                          <input
                            name="first_name"
                            type="text"
                            {...register("first_name")}
                            className={`form-control newcard2 ${
                              errors.first_name ? "is-invalid" : ""
                            }`}
                            placeholder="First Name"
                            aria-label=" First  Name"
                            aria-describedby="email-addon"
                          />
                          <div className="invalid-feedback">
                            {errors.first_name?.message}
                          </div>
                        </div>
                        <div className="mb-3 ">
                          <input
                            name="last_name"
                            type="text"
                            {...register("last_name")}
                            className={`form-control newcard2 ${
                              errors.last_name ? "is-invalid" : ""
                            }`}
                            placeholder="Last Name"
                            aria-label=" Last  Name"
                            aria-describedby="email-addon"
                          />
                          <div className="invalid-feedback">
                            {errors.last_name?.message}
                          </div>
                        </div>
                        <div className="mb-3 ">
                          <input
                            name="email"
                            type="Email"
                            {...register("email", {
                              required: true,
                              pattern:
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                            className={`form-control newcard2 ${
                              errors.email ? "is-invalid" : ""
                            }`}
                            placeholder="Email"
                            aria-label="Email"
                            aria-describedby="email-addon"
                          />
                          <div className="invalid-feedback">
                            {errors.email?.message}
                          </div>
                        </div>
                        <div className="mb-3 ">
                          <input
                            name="phone_number"
                            type="text"
                            {...register(
                              "phone_number",

                              {
                                required: true,
                              }
                            )}
                            className="form-control newcard2"
                            placeholder="phone Number"
                            aria-label="Email"
                            aria-describedby="email-addon"
                          />
                          <div className="invalid-feedback">
                            {errors.phone_number?.message}
                          </div>
                        </div>

                        <div className="mb-3 ">
                          <input
                            name="password"
                            type="password"
                            {...register("password", {
                              required: true,
                              pattern:
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                            className={`form-control newcard2 ${
                              errors.password ? "is-invalid" : ""
                            }`}
                            placeholder="Password"
                            aria-label="Password"
                            aria-describedby="password-addon"
                          />
                          <div className="invalid-feedback">
                            {errors.password?.message}
                          </div>
                        </div>
                        <div className="mb-3  ">
                          <input
                            name="confirmPassword"
                            type="password"
                            {...register("confirmPassword")}
                            className={`form-control newcard2 ${
                              errors.confirmPassword ? "is-invalid" : ""
                            }`}
                            placeholder="Confirm Password"
                            aria-label="Confirm Password"
                            aria-describedby="password-addon"
                          />
                          <div className="invalid-feedback">
                            {errors.confirmPassword?.message}
                          </div>
                        </div>
                        <div className="form-check form-check-info text-left">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue=""
                            id="flexCheckDefault"
                            defaultChecked=""
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault">
                            I agree the{" "}
                            <a
                              href="javascript:;"
                              className="text-dark font-weight-bolder">
                              Terms and Conditions
                            </a>
                          </label>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn bg-gradient-primary w-100 my-4 mb-2">
                            Sign up
                          </button>
                        </div>
                        <p className="text-sm mt-3 mb-0">
                          Already have an account?{" "}
                          <a
                            href="/Signin"
                            className="text-dark font-weight-bolder">
                            Sign in
                          </a>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/*   Core JS Files   */}
        {/* Github buttons */}
        {/* Control Center for Soft Dashboard: parallax effects, scripts for the example pages etc */}
      </>
    </div>
  );
};

export default Signup;
