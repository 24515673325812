import React from "react";
// import useFetch from "../hooks/useFetch";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
// import { makeRequest } from '../MakeReq/makeRequest';
/* import 'react-toastify/dist/ReactToastify.css'; */
import { useNavigate } from "react-router-dom";
import { storeUser } from "../helper";
import { useEffect } from "react";
// import { Form, Button } from 'semantic-ui-react';
import { useForm } from "react-hook-form";
//  import './new.css';
const intialUser = { password: "", identifier: "" };

const Signin = () => {
  const [user, SetUser] = useState(intialUser);
  // console.log(user)
  const navigate = useNavigate();
  const handleChange = ({ target }) => {
    SetUser((currentUser) => ({
      ...currentUser,
      [target.name]: target.value,
    }));
  };
  const handleLogin = async () => {
    const url = process.env.REACT_APP_API_URL + `/auth/local`;
    try {
      if (user.identifier && user.password) {
        const { data } = await axios.post(url, user, {
          headers: {
            Authorization: "bearer " + process.env.REACT_APP_API_TOKEN,
          },
        });
        if (data) {
          storeUser(data);
          SetUser(intialUser);
          navigate("/");
        }
      }
    } catch (error) {
      //  console.log(error,'error');
    }
  };
  return (
    <div>
      <>
        <div className="container  ">
          <div className="row">
            <div className="col-12">
              {/* Navbar */}

              {/* End Navbar */}
            </div>
          </div>
        </div>
        <main className="main-content  mt-0">
          <section>
            <div className="page-header min-vh-75">
              <div className="container">
                <div className="row">
                  <div className="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
                    <div className="card card-plain mt-8">
                      <div className="card-header pb-0 text-left bg-transparent">
                        <h3 className="font-weight-bolder text-info text-gradient">
                          Welcome back
                        </h3>
                        <p className="mb-0">
                          Enter your email and password to sign in
                        </p>
                      </div>
                      <div className="card-body">
                        <form role="form">
                          <label>Email</label>
                          <div className="mb-3">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email"
                              aria-label="Email"
                              aria-describedby="email-addon"
                              name="identifier"
                              value={user.identifier}
                              onChange={handleChange}
                              required
                            />
                          </div>
                          <label>Password</label>
                          <div className="mb-3">
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Password"
                              aria-label="Password"
                              aria-describedby="password-addon"
                              required
                              name="password"
                              value={user.password}
                              onChange={handleChange}
                            />
                          </div>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="rememberMe"
                              defaultChecked=""
                            />
                            <label
                              className="form-check-label"
                              htmlFor="rememberMe">
                              Remember me
                            </label>
                          </div>
                          <div className="text-center">
                            <button
                              type="button"
                              onClick={handleLogin}
                              className="btn bg-gradient-primary w-100 mt-4 mb-0">
                              Sign in
                            </button>
                          </div>
                        </form>
                      </div>
                      <div className="card-footer text-center pt-0 px-lg-2 px-1">
                        <p className="mb-4 text-sm mx-auto">
                          Don't have an account?
                          <a
                            href="/Signup"
                            className="text-info  font-weight-bold">
                            Sign up
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n8">
                      <div
                        className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                        style={{
                          backgroundImage:
                            'url("../assets/img/curved-images/curved6.jpg")',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        {/* -------- START FOOTER 3 w/ COMPANY DESCRIPTION WITH LINKS & SOCIAL ICONS & COPYRIGHT ------- */}

        {/* -------- END FOOTER 3 w/ COMPANY DESCRIPTION WITH LINKS & SOCIAL ICONS & COPYRIGHT ------- */}
        {/*   Core JS Files   */}
        {/* Github buttons */}
        {/* Control Center for Soft Dashboard: parallax effects, scripts for the example pages etc */}
      </>
    </div>
  );
};
export default Signin;
