import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { makeRequest } from "../MakeReq/makeRequest";
import "../App.css";
const Collectons = () => {
  const { data, loading, error } = useFetch(`/categories?populate=*`);
  //   console.log(data, "Collectons ");

  return (
    <div>

      <div class="  mobile34">
      <div style={{ color:'black',fontSize:'30px',marginBottom:'30px',fontWeight:'bold',textTransform:'capitalize'}}>Categories</div>
        <div  >

          <div class="row ">
            {data?.map((x) => (
              <div
                key={x.id}
                class="col-6 col-sm-2 col-md-2 col-lg-2 "
                style={{ marginBottom: "2%" }}>
                <div style={{justifyContent:'center',alignItems:'center'}}>
                <Link to={`/categories/${x.id}`}>
                    <div class="single-banner  "      style={{ backgroundImage: `url(${process.env.REACT_APP_UPLOAD_URL +
                        x?.attributes?.img?.data?.attributes?.url})`,height: "200px",overflow:'hidden',padding:"20px",borderRadius:'10px',
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        display: "grid",
                        placeItems: "center",verticalAlign:'center'
                        }}>




                    </div>
                    <h2 className=" " style={{color:'black',fontSize:'1.1rem',textAlign:'center',marginTop:'1rem'}}>
                        {x.attributes?.title}'s Collectons
                      </h2>
                      <p className=" " style={{color:'black',fontSize:'1rem',textAlign:'center'}}> {x.attributes?.title}</p>

                </Link>
                </div>

              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Collectons;

// <section class="hero-slider  newcard" style={{ marginBottom: "2rem" }}>
//   {/* Single Slider */}
//   <div
//     class="single-slider"
//     style={{
//       backgroundImage: `url(${
//         process.env.REACT_APP_UPLOAD_URL +
//         x?.attributes?.image?.data?.attributes?.url
//       })`,
//     }}>
//     <div class="container">
//       <div class="row no-gutters">
//         <div class="col-lg-9 offset-lg-3 col-12">
//           <div class="text-inner">
//             <div class="row">
//               <div class="col-lg-7 col-12">
//                 <div
//                   class="hero-text transparentInp"
//                   style={{
//                     padding: "1rem",
//                     borderRadius: "2rem",
//                   }}>
//                   <h1>
//                     <span style={{ color: "white" }}>
//                       {x.attributes?.discount}
//                     </span>
//                     {x.attributes?.title}
//                   </h1>
//                   <p style={{ color: "white" }}>
//                     {data?.attributes?.description}
//                   </p>
//                   <div class="button ">
//                     <Link to="/">
//                       <a class=" btn ">Shop Now!</a>
//                     </Link>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
//   {/*/ End Single Slider */}
// </section>;
// {
//   /*/ End Slider Area */
// }
