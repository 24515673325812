import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Checkout from "./pages/Checkout";

export const storeCurrency = (data) => {
    localStorage.setItem(
        "currency",
         data
    )
}
export const storeExchange = (data) => {
    localStorage.setItem(
        "Exchange",
         data
    )
}
export const currencyData = () => {
    const stringifiedUser = localStorage.getItem("currency")  || 'ETB';
    return  stringifiedUser  ;
}
export const ExchangeData = () => {
    const stringifiedUser = localStorage.getItem("Exchange")  || '""';
    return  stringifiedUser  ;
}
