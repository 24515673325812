import React from "react";
import useFetch from "../hooks/useFetch";
import { Link, redirect, useNavigate } from "react-router-dom";
import { UserData } from "../helper";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import img from "../chapa_img.jpg";
import { useState } from "react";
// get value of cart from storage
import { useLocation } from "react-router-dom";
import { currencyData, ExchangeData } from "../currency";
import "../App.css";
const Checkout = () => {
  // console.log(UserData(), "userdata");
  const [Sales_Id, setSales_Id] = useState(1);
  const Delivery = 0;

  const currency = currencyData();
  // console.log(currency, "currency");

  const Rate = ExchangeData();
  const Z = currency == "ETB" ? 1 : Rate;
  const [res, setRes] = useState();
  const navigate = useNavigate();

  const location = useLocation();

  const user_info = UserData();
  // console.log("user_info", user_info);

  const login_checker = () => {
    {
      if (UserData() == "") {
        navigate("/signin");
      }
    }
  };

  const Cart_Products = useSelector((state) => state.Cart_Products);
  // console.log(JSON.stringify(Cart_Products), "Cart_Products");

  const dispatch = useDispatch();
  const final_pay = Cart_Products.reduce(
    (total, item) => total + item.price * Z * item.quantity,
    0
  );
  // console.log(final_pay, "final_pay");

  const Cart = Cart_Products;
  const User = UserData();

  Cart.map((element) => {
    console.log(element.id, "id");
    console.log(element.title, "title");
    console.log(element.price, "price");
    console.log(element.quantity, "quantity");
    console.log(element.price * element.quantity, "total");
    console.log("element.price * element.quantity", "tx_rf");
  });

  // console.log(Cart, "cart");
  // console.log(User, "User");

  const url = process.env.REACT_APP_API_URL + `/new`;
  // console.log(url, "url");
  const datas = {
    cart: Cart,
    user: User,
    amount: final_pay,
    currency: currency,
    email: user_info?.email,
    first_name: user_info?.first_name,
    last_name: user_info?.last_name,
    phone_number: user_info?.phone_number,
    callback_url: process.env.REACT_APP_CURRENT_URL,
    return_url: process.env.REACT_APP_CURRENT_URL,
    Sales_Id: Sales_Id,
  };
  // console.log(datas, "datas");

  const headers = {
    Authorization: "bearer " + process.env.REACT_APP_API_TOKEN,
    "Content-type": "application/json; charset=UTF-8",
  };

  const post_to_backend = async () => {
    try {
      await axios
        .post(url, datas, { headers })
        .then(
          (
            response /* console.log(response.data?.data?.checkout_url, "response") */
          ) => window.open(response.data?.data?.checkout_url)
        );
    } catch (error) {
      setRes(error);
      console.log(error, "error");
    }
  };

  // console.log(res, "res");
  // console.log(res?.response?.data?.message?.amount, "res");

  {
    if (UserData() == "") {
      navigate("/signin");
    }
  }
  return (
    <div  className="checkoutmobile" >
      {/*} ##### Main Content Wrapper Start ##### */}


      <div className="  row " style={{marginTop:'1rem'}}>
      <div className="col-12 col-md-6">
      <div class="row newcard"  style={{ padding: "1.5rem", margin: "1rem" }}>
                      <div class="col-md-6 mb-3">
                        <label htmlFor="">Username</label>
                        <input
                          type="text"
                          class="form-control"
                          id="first_name"
                          value={user_info.username}
                          placeholder="First Name"
                          required
                        />
                      </div>
                      <div class="col-md-6 mb-3">
                        <label htmlFor="">Email</label>

                        <input
                          type="text"
                          class="form-control"
                          id="last_name"
                          value={user_info.email}
                          placeholder="Last Name"
                          required
                        />
                      </div>
                      <div class="col-md-6 mb-3">
                        <label htmlFor="">First Name</label>

                        <input
                          type="text"
                          class="form-control"
                          id="last_name"
                          value={user_info.first_name}
                          placeholder="Last Name"
                          required
                        />
                      </div>
                      <div class="col-md-6 mb-3">
                        <label htmlFor="">Last Name</label>

                        <input
                          type="text"
                          class="form-control"
                          id="last_name"
                          value={user_info.last_name}
                          placeholder="Last Name"
                          required
                        />
                      </div>
                      <div class="col-md-6 mb-3">
                        <label htmlFor="">Phone Number</label>

                        <input
                          type="text"
                          class="form-control"
                          id="last_name"
                          value={user_info.phone_number}
                          placeholder="Last Name"
                          required
                        />
                      </div>
                    </div>
        </div>
        <div  className="col-12 col-md-4">
          <div className="row newcard" style={{ padding: "1.5rem", margin: "1rem" }}>
            <div class="col-md-6 mb-3">  <h5>Cart Total</h5></div>
            <div className="col-12" >
              {
                Cart.map((element) => (
                  <div key={element.id} className="row" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", padding: "10px", marginTop: '10px', borderRadius: '10px' }}>
                       <div className="col-6" style={{fontWeight:'bold'}}>
               name   { element.title}
                    </div>
                    <div className="col-6">
               id {element.id}
                    </div>

                    <div className="col-6">
                price  { element.price}
          </div>

                    <div className="col-6">
                     quantity {element.quantity }
            </div>
                    <div className="col-6">
                    total price {element.price * element.quantity}
     </div>
                    <div className="col-6">
                    {element.price * element.quantity}
              </div>

                   </div>)

                )
              }
            </div>
            <div class="col-md-12 mb-3" style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px", padding: "10px", marginTop: '10px', borderRadius: '10px' }}> <ul class="summary-table">
                    <li>
                      <span>subtotal:</span>{" "}
                      <span>
                        {" "}
                        {Cart_Products.reduce(
                          (total, item) =>
                            total + item.price * Z * item.quantity,
                          0
                        ).toFixed(2)}
                        {currency == "ETB" ? " ETB" : " USD"}
                      </span>
                    </li>
                    <li>
                      <span>delivery:</span>{" "}
                      <span>
                        {" "}
                        {Delivery.toFixed(2)}
                        {currency == "ETB" ? " ETB" : " USD"}
                      </span>
                    </li>
                    <li>
                      <span>total:</span>{" "}
                      <span>
                        {final_pay.toFixed(2)}
                        {currency == "ETB" ? " ETB" : " USD"}
                      </span>
                    </li>
                  </ul></div>
            <div class="col-md-6 mb-3">
                    <div>
                      {res?.response?.data?.status == "failed" ? (
                        <div
                          class="alert alert-danger"
                          style={{ marginTop: "20px" }}>
                          <strong>{res?.response?.data?.status}</strong>
                          <br></br> {res?.response?.data?.message?.amount}
                        </div>
                      ) : (
                        ""
                      )}

                      <h6>Pay With Chapa </h6>
                      <button type="button" onClick={post_to_backend}>
                        <img src={img} style={{ width: "100px" }}></img>
                      </button>
                    </div></div>

         </div>
        </div>
</div>

      <div>











</div>


      {/*} ##### Main Content Wrapper End ##### */}
    </div>
  );
};
export default Checkout;
