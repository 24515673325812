import React, { Component } from "react";
import { Link } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { makeRequest } from "../MakeReq/makeRequest";
import { currencyData, ExchangeData } from "../currency";

const OnsaleBest_Seller_Top_Viewed = () => {
  const currency = currencyData();
  const Rate = ExchangeData();

  const Z = currency == "ETB" ? 1 : Rate;
  console.log(Z, "z");
  const onsale = useFetch(
    `/products?populate=*&[filters][onsale][$eq]='true'&sort=createdAt:desc`
  );
  const new_onsale = onsale?.data?.slice(0, 3);

  // console.log(new_onsale, "new_onsale");

  const bestsale = useFetch(
    `/products?populate=*&[filters][bestsale][$eq]='true'&sort=createdAt:desc`
  );
  const new_bestsale = bestsale?.data?.slice(0, 3);

  //   console.log(bestsale.data,'bestsale')
  const topview = useFetch(
    `/products?populate=*&[filters][topview][$eq]='true'&sort=createdAt:desc`
  );
  const new_topview = topview?.data?.slice(0, 3);

  //   console.log(topview.data,'topview')
  return (
    <div className="row" style={{marginTop:'10%'}}>
      <div class="col-lg-4 col-md-6 col-12">
        <div class="row"  >
          <div class="col-12">
            <div class="shop-section-title">
              <h1>On sale</h1>
            </div>
          </div>
        </div>
        {/* Start Single List  */}
        {new_onsale?.map((x) => (
          <div className="single-list"    style={{backgroundColor:'white',borderRadius:'10px',boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
            <div className="row"  >
              <div className="col-lg-6 col-md-6 col-12" >
                <div className="list-image overlay"  >
                  <img
                    src={
                      process.env.REACT_APP_UPLOAD_URL +
                      x.attributes?.gallery?.data[0].attributes?.url
                    }
                    alt="#" style={{height:'180px'}}
                  />
                  <a href={`/product/${x.id}`} className="buy">
                    <i className="fa fa-shopping-bag"></i>
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 no-padding">
                <div className="content">
                  <h4 className="title">
                    <a href={`/product/${x.id}`}>{x.attributes.title}</a>
                  </h4>
                  <p className="price with-discount">
                    {(x.attributes?.price * Z).toFixed(2)}{" "}
                    {currency == "ETB" ? " ETB" : " USD"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div class="col-lg-4 col-md-6 col-12">
      <div className="row"   >
          <div class="col-12">
            <div class="shop-section-title">
              <h1>Best Seller</h1>
            </div>
          </div>
        </div>
        {/* Start Single List  */}
        {new_bestsale?.map((x) => (
          <div className="single-list"  style={{backgroundColor:'white',borderRadius:'10px',boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}} >
          <div className="row"   >
              <div className="col-lg-6 col-md-6 col-12" >
                <div className="list-image overlay">
                  <img
                    src={
                      process.env.REACT_APP_UPLOAD_URL +
                      x.attributes?.gallery?.data[0].attributes?.url
                    }
                    alt="#" style={{height:'180px'}}
                  />
                  <a href={`/product/${x.id}`} className="buy">
                    <i className="fa fa-shopping-bag"></i>
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 no-padding">
                <div className="content">
                  <h5 className="title">
                    <a href={`/product/${x.id}`}>{x.attributes?.title}</a>
                  </h5>
                  <p className="price with-discount">
                    {(x.attributes?.price * Z).toFixed(2)}
                    {currency == "ETB" ? " ETB" : " USD"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* End Single List  */}
      </div>
      <div class="col-lg-4 col-md-6 col-12">
      <div className="row"  >
          <div class="col-12">
            <div class="shop-section-title">
              <h1>Top viewed</h1>
            </div>
          </div>
        </div>
        {/* Start Single List  */}

        {new_topview?.map((x) => (
          <div className="single-list"  style={{backgroundColor:'white',borderRadius:'10px',boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}} >
           <div className="row"  >
              <div className="col-lg-6 col-md-6 col-12" >
                <div className="list-image overlay">
                  <img
                    src={
                      process.env.REACT_APP_UPLOAD_URL +
                      x.attributes?.gallery?.data[0].attributes?.url
                    }
                    alt="#" style={{height:'180px'}}
                  />

                  <a href={`/product/${x.id}`} className="buy">
                    <i className="fa fa-shopping-bag"></i>
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 no-padding">
                <div className="content" >
                  <h5 className="title">
                    <a href={`/product/${x.id}`}>{x.attributes?.title}</a>
                  </h5>
                  <p className="price with-discount">
                    {(x.attributes?.price * Z).toFixed(2)}{" "}
                    {currency == "ETB" ? " ETB" : " USD"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* End Single List  */}
      </div>
    </div>
  );
};

export default OnsaleBest_Seller_Top_Viewed;
