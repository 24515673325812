import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../App.css";
const Intro = () => {
  return (
    <div className="mobile01">
      <div className="   row ">
        <div  >
          <div className=" justify-content-between mobile02">
            <div
              className=" col-lg-8 newcard  "
              style={{
                padding: "2rem",
                marginTop: "1.5rem",
                marginBottom: "3.5rem",
                marginLeft: "1rem",
                borderRadius: "1.5rem",backgroundColor:'white'
              }}>
              <div className="intro-excerpt">
                <h1>Welcome To Etho Express</h1>
                <p
                  className="mb-4"
                  style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                  We empower businesses and individuals to thrive in the digital
                  marketplace. We believe in providing a convenient, secure, and
                  enjoyable online shopping experience for our customers while
                  offering a robust platform for sellers to showcase their
                  products and reach a global audience.
                </p>
                <p>
                  <Link to="/">
                    <a
                      href=""
                      className="btn btn-primary me-2"
                      style={{ color: "white" }}>
                      Shop Now
                    </a>
                  </Link>
                  <Link to="/">
                    <a href="#" className="btn btn-white-outline">
                      Explore
                    </a>
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
