import "../App.css";
import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import logo2 from "../asset/images/3.png";
import { storeCurrency, storeExchange } from "../currency";
// import logo from ".././";
import useFetch from "../hooks/useFetch";
import { Link, Routes, Route, useNavigate } from "react-router-dom";
import Search from "./Search";
import {
  incrementQuantity,
  decrementQuantity,
  removeItem,
} from "../../src/redux/cartSlice";
import { UserData } from "../helper";
import { useLocation } from "react-router-dom";
import { currencyData, ExchangeData } from "../currency";
const Mobilenav = () => {
  // const [isHovered, setIsHovered] = useState();
  // exchange api
  const navigate = useNavigate();
  const Exchange = useFetch(`/converts?populate=&`);
  const USD = Exchange?.data?.map((x) => x.attributes?.amount);

  //  console.log(USD, "USD");
  const USD_RATE = ExchangeData();
  //  console.log(USD_RATE, "USD_RATE");

  const [currency, setCurrency] = useState(currencyData());
  //  const currency = currencyData();
  //  console.log(Exchange?.data, "Exchange");

  const theprice = currency == "ETB" ? 100 * 1 : 100 * USD;

  // console.log(theprice, "theprice");

  const location = useLocation();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  // console.log(windowSize)
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    // Set initial window size
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  // const navigate = useNavigate();
  const { data, loading, error } = useFetch(`/categories?populate=products`);
  // get cart state
  const Cart_Products = useSelector((state) => state.Cart_Products);
  const dispatch = useDispatch();
  const final_pay = Cart_Products.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );
  const getTotalQuantity = () => {
    let total = 0;
    Cart_Products.forEach((item) => {
      total += item.quantity;
    });
    //  console.log(UserData() ? 'gg':'hh','UserData')
    return total;
  };
  const logout = () => {
    localStorage.removeItem("user", {});
    navigate("/");
  };
  return (
    <div className="mobileView" >
      <nav className="navbar fixed-top  navbar-dark row" style={{backgroundColor:"white",   }}>
        <div className="col-4" style={{display:'flex',flexDirection:'row'}}>
        <Link to="/">
                  <img
                    src={logo2}
                    style={{ height: "2.5rem",  }}></img>
          </Link>
          {/* <div style={{color:'black',textTransform:'uppercase',fontWeight:'bold',fontSize:'16px',lineHeight:'1.2',marginLeft:'0.5rem'}}>etho<br></br>express</div> */}
        </div>
        <div className="col-6">
        <div style={{  marginLeft: "-2rem" ,marginTop:'-2rem',marginBottom:'-2rem',scale:'0.9'}}>
                  <Search></Search>{" "}
                </div>


        </div>

        <div className="col-2">
          <div style={{  marginLeft: "0.5rem" ,marginTop:'-1rem',marginBottom:'-2rem'}}>
          <Link to="/profile">
                    <a
                      className="navbar-brand ms-lg-0 ms-3 "
                      href=" ">
                      <i
                        class="fa fa-user"
                        style={{ fontSize: "1.5rem",color:"black" }}
                        aria-hidden="true"></i>
                    </a>
                  </Link>
          </div>

          </div>
</nav>
      <nav className="navbar fixed-bottom  navbar-dark row" style={{backgroundColor:"white",scale:"0.8",borderRadius:'20px'}}>
        <div className="col-3" style={{color:"black"}}>


              <Link to="/Home">
                <a
                  className="navbar-brand   "
                  href="/Home">
                  <i
                    class="fa fa-home"
                    style={{ fontSize: "1.5rem",color:'black' }}
                    aria-hidden="true"></i>
                </a>

          <div style={{fontWeight:'light'}}>Home</div>  </Link>
              </div>
              <div className="col-3" style={{color:"black"}}>

          <Link to="/">
                <a
                  className="navbar-brand  "
                  href="">
                  <i
                    class="fa fa-shopping-bag"
                      style={{ fontSize: "1.5rem",color:'black' }}
                    aria-hidden="true"></i>
                </a>

          <div>
          Shop
          </div>    </Link>
      </div>

      <div className="col-3" style={{color:"black"}}>

          <Link to="/cart">
                <a
                  className="navbar-brand  "
                  href="/">
                  <i
                    class="fa fa-shopping-cart"
                      style={{ fontSize: "1.5rem",color:'black' }}
                    aria-hidden="true">

                  </i>
              {getTotalQuantity() || 0}
                </a>
                 <div>
          Cart
          </div></Link>

</div>


<div className="col-3" style={{color:"black"}}>



                <select

                  style={{
marginLeft:"6px"
                  }}
                  type="text"
                  name="pagination"
                  onChange={(e) => {
                    setCurrency(e.target.value);
                    storeCurrency(e.target.value);
                    storeExchange(USD);
                    window.location.reload();
                  }}>
                  <option
                    value={currency}
                    >
                    {currency}
                  </option>
                  <option
                    value={"ETB"}
                     >
                    ETB
                  </option>
                  <option
                   >
                    USD
                  </option>
                </select>


        <ul  style={{ scale: "0.8" }}>
                  {UserData() ? (
                    <li>
                      <i className="ti-power-off"></i>
                      <button
                        type="button"
                        onClick={logout}
                        style={{
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          paddingLeft: "10px",
                          paddingRight: "10px",

                          marginLeft: "0px",
                          border: "none",
                          backgroundColor: 'black',
                         color:'white'

                        }}>
                        Sign Out
                      </button>
                    </li>
                  ) : (
                    <li>
                      <i className="ti-power-off"></i>
                      <button
                        onClick={(e) => navigate("/signin")}
                        style={{
                          paddingTop: "10px",
                          paddingBottom: "10px",
                          paddingLeft: "10px",
                          paddingRight: "10px",

                          marginLeft: "0px",
                          border: "none",
                          backgroundColor: 'black',
                         color:'white'
                        }}>
                        Sign in
                      </button>
                    </li>
                  )}
                </ul>
</div>


</nav>

      {/* <div className="mobileView" style={{ marginBottom: "12rem" }}>
        <div className="  ">
          <div
            className="row fixed-top  "
            style={{
              boxShadow:
                "      rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
            }}>
            <div style={{ backgroundColor: "white" }}>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  height: "4rem",
                }}>
                <Link to="/">
                  <img
                    src={logo2}
                    style={{ height: "2.5rem", margin: "1rem" }}></img>
                </Link>
                <div style={{ scale: "0.8", marginTop: "-1rem" }}>
                  <Search></Search>{" "}
                </div>

                <div style={{ paddingTop: "1.3rem" }}>
                  <Link to="/profile">
                    <a
                      className="navbar-brand ms-lg-0 ms-3 "
                      href=" ">
                      <i
                        class="fa fa-user"
                        style={{ fontSize: "1.2rem" }}
                        aria-hidden="true"></i>
                    </a>
                  </Link>
                </div>
              </div>


            </div>
          </div>
          <div
            className="row fixed-bottom  "
            style={{ backgroundColor: "white" }}>
            <div className="col-4">
              <Link to="/">Home
                <a
                  className="navbar-brand ms-lg-0 ms-3 "
                  href="/">
                  <i
                    class="fa fa-home"
                    style={{ fontSize: "1rem" }}
                    aria-hidden="true"></i>
                </a>
              </Link>
            </div>
            <div className="col-4">
              <Link to="/shopgrid">Shop
                <a
                  className="navbar-brand ms-lg-0 ms-3 "
                  href="">
                  <i
                    class="fa fa-shopping-bag"
                    style={{ fontSize: "1rem" }}
                    aria-hidden="true"></i>
                </a>
              </Link>
            </div>
            <div className="col-4">
              <Link to="/cart">Cart
                <a
                  className="navbar-brand ms-lg-0 ms-3 "
                  href="/">
                  <i
                    class="fa fa-shopping-cart"
                    style={{ fontSize: "1rem" }}
                    aria-hidden="true">
                    {" "}
                    &nbsp;
                  </i>
                  <span>{getTotalQuantity() || 0} items</span>
                </a>
              </Link>
            </div>

            <div className="col-6">
              <div className="shop-shorter">
                <div className="single-shorter ">
                Currency
                  <select
                    className=" "
                    style={{
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}
                    type="text"
                    name="pagination"
                    onChange={(e) => {
                      setCurrency(e.target.value);
                      storeCurrency(e.target.value);
                      storeExchange(USD);
                      window.location.reload();
                    }}>
                    <option
                      value={currency}
                      style={{
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}>
                      {currency}
                    </option>
                    <option
                      value={"ETB"}
                      style={{
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}>
                      ETB
                    </option>
                    <option
                      value={"USD"}
                      style={{
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}>
                      USD
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-6" style={{ scale: "0.8" }}>
              <div>
                <ul className="list-main" style={{ scale: "0.8" }}>
                  {UserData() ? (
                    <li>
                      <i className="ti-power-off"></i>
                      <button
                        type="button"
                        onClick={logout}
                        style={{
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          marginTop: "-10px",
                          marginLeft: "0px",
                          border: "none",
                        }}>
                        Sign Out
                      </button>
                    </li>
                  ) : (
                    <li>
                      <i className="ti-power-off"></i>
                      <button
                        onClick={(e) => navigate("/signin")}
                        style={{
                          paddingTop: "5px",
                          paddingBottom: "5px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          marginTop: "-10px",
                          marginLeft: "0px",
                          border: "none",
                        }}>
                        Sign in
                      </button>
                    </li>
                  )}
                </ul>
              </div>

            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Mobilenav;
