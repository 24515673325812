import React from "react";

import useFetch from "../hooks/useFetch";
import { Link, Routes, Route, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const Search = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  // console.log(windowSize)
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    // Set initial window size
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const { data, loading, error } = useFetch(
    /* find multiple Products(subcategories) with subCats, maxPrice, sort, catId */

    `/categories?populate=*&`
  );
  /*     console.log(data, 'search') */
  // the search  field function
  const [search, setSearch] = useState("");
  const [categories, setCategories] = useState();
  const navigate = useNavigate();
  const pass = {
    search: search,
  };
  /*  console.log(selectedCats, 'search')
    console.log(search, 'search') */

  const handleSearch = () => {
    navigate("/", { state: pass });
  };
  return (
    <div className="row">
      <div className="col-12">
        <div
          className="search-bar-top"
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: "30px",
            marginLeft: "5px",
            marginBottom: "20px",
          }}>
          <input
            name="search"
            placeholder="Search Here....."
            style={{ width: "12em", padding: "1rem", height: "2.8rem" }}
            type="text"
            onChange={(e) => setSearch(e.target.value)}
          />

          <button
            class="btn btn-light"
            onClick={handleSearch}
            style={{ marginLeft: "0.3rem" }}>
            <i className=" fa fa-search"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Search;
