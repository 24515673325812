import React from "react";
import { Link } from "react-router-dom";
import List from "./List";
import useFetch from "../hooks/useFetch";
import onsalesvg from "../asset/images/sale-svgrepo-com (1).svg";
// import Carousel from "react-bootstrap/Carousel";
import { useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
import { currencyData, ExchangeData } from "../currency";

const Card = ({ item, currency }) => {
  const [index, setIndex] = useState(1);
  const Rate = ExchangeData();
  // const currency = currencyData();
  // console.log("currency", currency);

  const Z = currency == "ETB" ? 1 : Rate;
  const PRICE = item?.attributes?.price * Z;

  // console.log("Z", Z);
  // console.log("PRICE", PRICE);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  return (
    <div className="  ">
      <div>
        <div>
          <Link to={`/product/${item.id}`}>
            <div
              class="single-product     newcard      "
              style={{ padding: "4%",backgroundColor:'white' }}>
              <div class="product-img">
                <a  >
                  <img
                    class="default-img"
                    src={
                      process.env.REACT_APP_UPLOAD_URL +
                      item?.attributes?.gallery?.data[0]?.attributes?.url
                    }
                    alt={item?.attributes?.title}
                    style={{ height: "200px" , overflow:'hidden',borderRadius:'10px' }}
                  />
                  <img
                    class="hover-img"
                    src={
                      process.env.REACT_APP_UPLOAD_URL +
                      item?.attributes?.gallery?.data[1]?.attributes?.url
                    }
                    alt={item?.attributes?.title}
                    style={{ height: "15rem", objectFit: "cover",overflow:'hidden' }}
                  />
                </a>
                <div class="button-head">
                  <div class="product-action">
                    <a
                      data-toggle="modal"
                      data-target="#exampleModal"
                      title="Quick View"
                      href="#">
                      <i class=" ti-eye"></i>
                      <span>Quick Shop</span>
                    </a>
                    <a title="Wishlist" href="#">
                      <i class=" ti-heart "></i>
                      <span>Add to Wishlist</span>
                    </a>
                    <a title="Compare" href="#">
                      <i class="ti-bar-chart-alt"></i>
                      <span>Add to Compare</span>
                    </a>
                  </div>
                  <div class="product-action-2">
                    <a title="Add to cart" href="#">
                      Add to cart
                    </a>
                  </div>
                </div>
              </div>
              <div class="product-content">
                <h3>
                  <a>{item?.attributes?.title}</a>
                </h3>
                <h5>
                  <a>{item?.attributes?.desc?.substring(0, 40)}</a>
                </h5>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {item?.attributes?.status ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}>
                      <div
                        style={{
                          width: "0.8rem",
                          height: "0.8rem",
                          borderRadius: "50%",
                          backgroundColor: "green",
                          marginTop: "0.15rem",
                        }}></div>{" "}
                      <div
                        style={{
                          color: "green",
                          fontWeight: "bold",
                          marginLeft: "0.3rem",
                        }}>
                        IN Stock{" "}
                      </div>{" "}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}>
                      <div
                        style={{
                          width: "0.8rem",
                          height: "0.8rem",
                          borderRadius: "50%",
                          backgroundColor: "red",
                          marginTop: "0.15rem",
                        }}></div>
                      <div
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          marginLeft: "0.3rem",
                        }}>
                        Out Of Stock{" "}
                      </div>{" "}
                    </div>
                  )}

                  {item?.attributes?.onsale ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}>
                      <img
                        src={onsalesvg}
                        alt=""
                        style={{
                          width: "2rem",
                          marginLeft: "0.5rem",
                          marginTop: "-0.5rem",
                        }}
                      />
                    </div>
                  ) : (
                    " "
                  )}
                </div>
                <div class="product-price">
                  <span>
                    {" "}
                    {PRICE.toFixed(2)}
                    {currency == "ETB" ? " ETB" : "$"}
                  </span>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Card;

//           <Link to={`/product/${item.id}`}>
//           {/*} Single Product Area */}
//              <div >
//                  <div class="single-product-wrapper " style={{width:"15rem",objectFit:"cover",padding:"10px"}} >
//                      {/*} Product Image */}
//                      <div class="product-img">
//                          <img      src={
//                process.env.REACT_APP_UPLOAD_URL +
//                item.attributes?.gallery?.data[0].attributes?.url
//              } alt={item?.attributes?.title}   style={{height:"15rem",objectFit:"cover"}}/>
//                          {/*} Hover Thumb */}
//                          <img class="hover-img"  src={
//                process.env.REACT_APP_UPLOAD_URL +
//                item.attributes?.gallery?.data[1]?.attributes?.url
//              } alt={item?.attributes?.title}  style={{height:"15rem",objectFit:"cover"}}/>
//                      </div>

//                      {/*} Product Description */}
//                      <div class="product-description d-flex align-items-center justify-content-between">
//                          {/*} Product Meta Data */}
//                          <div class="product-meta-data">
//                              <div class="line"></div>
//                              <p class="product-price" style={{fontSize:"18px"}}> {PRICE.toFixed(2)}
//            {currency == "ETB" ? " ETB" : " USD"} </p>
//                              <a href="product-details.html">
//                                  <h6  style={{fontSize:"14px"}}>{item?.attributes?.title}</h6>
//                              </a>
//                          </div>
//                          {/*} Ratings & Cart */}
//                          <div class="ratings-cart text-right">
//                              <div class="ratings">
//                                  <i class="fa fa-star" aria-hidden="true"></i>
//                                  <i class="fa fa-star" aria-hidden="true"></i>
//                                  <i class="fa fa-star" aria-hidden="true"></i>
//                                  <i class="fa fa-star" aria-hidden="true"></i>
//                                  <i class="fa fa-star" aria-hidden="true"></i>
//                              </div>

//                          </div>
//                      </div>
//                  </div>
//              </div>

//  </Link>
