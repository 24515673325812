

import './App.css';
import './new.css';

import '../src/asset/style.css'
// import '../src/asset/css/font-awesome.css'
// import '../src/asset/css/bootstrap.css'
// import '../src/asset/css/niceselect.css'
// import '../src/asset/css/magnific-popup.min.css'
// import '../src/asset/css/responsive.css'
// import '../src/asset/css/jquery.fancybox.min.css'
// import '../src/asset/css/themify-icons.css'

import '../src/asset/css/animate.css'
// import '../src/asset/css/flex-slider.min.css'
// import '../src/asset/css/owl-carousel.css'
// import '../src/asset/css/slicknav.min.css'
import '../src/asset/css/reset.css'

import "./resource/css/nucleo-icons.css";
import "./resource/css/nucleo-svg.css";
import "./resource/css/soft-ui-dashboard.css";

// import '../src/asset/js/jquery.min.js'
// import '../src/asset/js/jquery-migrate-3.0.0.js'
// import '../src/asset/js/jquer-ui.min.js'
// import '../src/asset/js/popper.min.js'
// import '../src/asset/js/bootstrap.min.js'
// import '../src/asset/js/colors.js'
// import '../src/asset/js/slicknav.min.js'
// import '../src/asset/js/owl-carousel.js'
// import '../src/asset/js/magnific-popup.js'
// import '../src/asset/js/waypoints.min.js'
// import '../src/asset/js/finalcountdown.min.js'
// import '../src/asset/js/flex-slider.js'
// import '../src/asset/js/scrollup.js'
// import '../src/asset/js/onepage-nav.min.js'
// import '../src/asset/js/easing.js'
// import '../src/asset/js/active.js'
// import '../src/asset/js/nicesellect.js'

// import Nav from "./component/Nav"
import Foot from "./component/Foot"
import Home from "./pages/Home"
// import Blog from "./pages/Blog"
import Cart from "./pages/Cart"
import Checkout from "./pages/Checkout"
import Contact from "./pages/Contact"
import Shopgrid from "./pages/Shopgrid"
// import About from "./pages/About"
import Signin from "./pages/Signin"
// import Register from "./pages/Register"
import Profile from "./pages/Profile"
import Sidebar from "./component/Sidebar"
import Mobilenav from "./component/Mobilenav"
import logo from './logo.svg';
import Signup from './pages/Signup';
import Intro from './component/Intro'
import Virtual from './pages/Virtual';
// import $ from 'jquery';
import Product from './pages/Product';
import About from './pages/About';
import Verify from './pages/Verify';

import { BrowserRouter,Routes, Route, Link } from "react-router-dom";
import { Protector } from "./helper";



import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { storeCurrency, storeExchange } from "./currency";
// import logo from ".././";
import useFetch from "./hooks/useFetch";
import {   useNavigate } from "react-router-dom";
import Search from "./component/Search";
import {
  incrementQuantity,
  decrementQuantity,
  removeItem,
} from "../src/redux/cartSlice";
import { UserData } from "./helper";
import { useLocation } from "react-router-dom";
import { currencyData, ExchangeData } from "./currency";
import Categories from './pages/Categories';

const App = () => {



  return (
    <div>
       <BrowserRouter>
      {/* <Nav></Nav> */}
      <div className='container-fluid container2'>

      <Mobilenav></Mobilenav>
        <div className='row row2'  >
          <div className='  col-md-4 col-lg-3 col-xl-2 sidebar2 '>
          <div className=''>
              <Sidebar></Sidebar>

              </div>
          </div>
          <div className='col-sm-12 col-md-8 col-lg-9 col-xl-10 content2  ' >
          <div className=''>
          <Routes>
          <Route path="/Home" element={<Home/>} />
          <Route path="/categories/:id" element={<Categories/>} />
          <Route path="/" element={<Shopgrid/>} />
      <Route path="/product/:id" element={<Product/>} />
      <Route path="/cart" element={<Cart/>} />
      <Route path="/checkout" element={<Protector component={Checkout}>
          <Checkout/>
          </Protector> } />
                      <Route path="/signin" element={<Signin/>} />
                      <Route path="/about" element={<About/>} />
        <Route path="/Signup" element={<Signup/>} />
        <Route path="/profile" element={<Profile/>} />
        <Route path="/verify/:tx_ref" element={<Verify/>} />
          </Routes>

              <Foot></Foot>

              </div>
          </div>

        </div>
</div>
      </BrowserRouter>
    </div>

  );
}

export default App;
