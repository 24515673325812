import React, { Component } from "react";
import Card from "./Card";
import useFetch from "../hooks/useFetch";
const List = ({
  price_min,
  price_max,
  sort,
  sortby,
  search,
  currency,
  selectedCats,

  PER_PAGE,
  offset,
  id,
}) => {
  const { data, loading, error } = useFetch(
    /* find multiple Products(subcategories) with subCats, maxPrice, sort, catId */

    `/products?populate=*&[filters][price][$gte]=${price_min} &[filters][price][$lte]=${price_max} &sort=${sortby}:${sort}${selectedCats.map(
      (item) => `&[filters][sub_categories][id]=${item}`
    )}`
  );

  /* console.log(selectedCats,'selectedCats list')
     console.log(data,'list')
     console.log(offset,'offset')
    */

  const currentPageData = data?.slice(offset, offset + PER_PAGE);
  /*  console.log(currentPageData,'currentPageData') */
  /*  console.log(currentPageData) */
  //console.log(data, "data");
  //console.log(subCats, "hello");
  /* console.log(search) */
  return (
    <div className="  row" >
      {loading
        ? "loading"
        : currentPageData
            ?.filter((item) => {
              return search.toLowerCase() === " "
                ? item
                : item.attributes?.title?.toLowerCase().includes(search);
            })

            .map((item) => (
              <div key={item.id} className="col-6 col-sm-4 col-md-6 col-lg-3 col-xl-3">
                <Card item={item} currency={currency} />
              </div>
            ))}
    </div>
  );
};

export default List;
