import React, { Component } from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { currencyData } from "../currency";
import { useLocation } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import Accordion from "react-bootstrap/Accordion";
import List from "../component/List";
import Recentpost from "../component/Recentpost";

const Shopgrid = () => {
  const currency = currencyData();
  // console.log("currency", currency);
  const Exchange = useFetch(`/converts?populate=&`);
  console.log(Exchange, "Exchange");
  // const USD = Exchange?.data?.map((x) => x.attributes?.amount);

  // gets the value of search & category from navigation
  const location = useLocation();
  const pass = location.state;
  // category from navigation

  const search = pass ? pass.search : "";

  //product data
  const sub_category_data = useFetch(`/sub-categories?populate=*&`);
  const sub_categ_data = sub_category_data.data;

  const categ_new = useFetch(`/categories?populate=*&`);
  const categ_new_data = categ_new?.data;
  console.log(categ_new_data, "categ_new_data");
  const { data, loading, error } = useFetch(`/products?populate=category`);
  const merchant = useFetch(`/merchants?populate=*`);
  console.log("merchant", merchant);
  // console.log(data,'main')
  // state that contain array of selected categories
  const [selectedCats, setSelectedCats] = useState([]);

  // console.log("selectedCats", selectedCats);

  const id = useParams().id;
  const [PER_PAGE, setPER_PAGE] = useState(32);
  /* console.log(PER_PAGE); */
  const [sort, setSort] = useState("desc");
  const [sortby, setSortby] = useState("createdAt");
  /* console.log('sortby',sortby) */
  const [price, setPrice] = useState(100000);
  const [price_min, setPrice_min] = useState(0);
  const [price_max, setPrice_max] = useState(1000000);
  /* console.log(price_min,price_max,'price min max') */
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedsubCats, setSelectedsubCats] = useState([]);
  //console.log(currentPage, 'current page')
  //console.log(PER_PAGE, 'PER_PAGE page')

  const offset = currentPage * PER_PAGE;
  //console.log(offset, 'offset')
  const pageCount = Math.ceil(data?.length / PER_PAGE);
  //console.log(pageCount, 'pageCount')
  const subcateg = useFetch(
    /* find multiple Products(subcategories) with subCats, maxPrice, sort, catId */

    `/sub-categories?populate=*&`
  );
  const categ = subcateg?.data;

  const handleprice = (e) => {
    if (e.target.value == 1 - 100000) {
      const min = 0;
      const max = 100000;
      setPrice_min(min);
      setPrice_max(max);
    }
    if (e.target.value == 1 - 500) {
      const min = 0;
      const max = 500;
      setPrice_min(min);
      setPrice_max(max);
    }
    if (e.target.value == 500 - 1000) {
      const min = 500;
      const max = 1000;
      setPrice_min(min);
      setPrice_max(max);
    }
    if (e.target.value == 1000 - 5000) {
      const min = 1000;
      const max = 5000;
      setPrice_min(min);
      setPrice_max(max);
    }
    if (e.target.value == 5000 - 10000) {
      const min = 5000;
      const max = 10000;
      setPrice_min(min);
      setPrice_max(max);
    }
    if (e.target.value == 10000 - 50000) {
      const min = 10000;
      const max = 50000;
      setPrice_min(min);
      setPrice_max(max);
    }
    if (e.target.value == 50000 - 100000) {
      const min = 50000;
      const max = 100000;
      setPrice_min(min);
      setPrice_max(max);
    }
    if (e.target.value == 100000 - 500000) {
      const min = 100000;
      const max = 500000;
      setPrice_min(min);
      setPrice_max(max);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setSelectedCats(
      isChecked
        ? [...selectedCats, value]
        : selectedCats.filter((item) => item !== value)
    );
  };
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  //console.log(selectedCats, "the search");
  //console.log(subcateg, "the search");
  const previousPage = () => {
    if (currentPage !== 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <div className="shop1" >
      <div class="js" >



        <section
          class="    shop  "
          style={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <div class=" ">

            <div class="row">
              <div class="col-lg-3 col-md-4 ">
              {/*   mobile view start */}
                <div className="shop2">


              <Accordion
                            style={{
                              marginLeft: "-1rem",

                              overflow: "hidden",
                            }}>
                            <Accordion.Item
                              eventKey="0"
                              // className="bg-gradient-primary"
                              style={{


                                borderRadius: "0.4rem",
                                padding: "0.05rem",
                              }}>
                              <Accordion.Header>
                                <div
                                  style={{
                                    backgroundColor: "none",
                                    borderColor: "black",
                                    color: "black",
                                    fontSize: "2rem",
                            fontWeight: 'normal',

                                  }}>
                         <span>{ " "}</span><i class="fa fa-bars" aria-hidden="true"></i>
                                </div>
                              </Accordion.Header>

            <Accordion.Body>
            <div >
                        <div class=" ">
                        <div class="shop-sidebar">
                  {/* Single Widget */}
                  <div class="single-widget category newcard">
                    <h3 class="title">Categories</h3>
                    <ul class="categor-list">
                      {categ_new_data?.map((x) => (
                        <li key={x.id}>
                          <Accordion
                            style={{
                              marginLeft: "-1rem",

                              overflow: "hidden",
                            }}>
                            <Accordion.Item
                              eventKey="0"
                              // className="bg-gradient-primary"
                              style={{
                                backgroundColor: "#CB0C9f",

                                borderRadius: "0.4rem",
                                padding: "0.05rem",
                              }}>
                              <Accordion.Header>
                                <div
                                  style={{
                                    backgroundColor: "none",
                                    borderColor: "red",
                                    color: "white",
                                    fontSize: "0.7rem",
                                    fontWeight: "bold",
                                  }}>
                                  {" "}
                                  {x.attributes?.title?.substring(0, 20)}
                                </div>
                              </Accordion.Header>
                              {x.attributes?.sub_categories?.data?.map((x) => (
                                <Accordion.Body
                                  style={{ backgroundColor: "none" }}>
                                  <li key={x.id}>
                                    <input
                                      type="checkbox"
                                      id={x.id}
                                      value={x.id}
                                      onChange={handleChange}
                                      style={{ margin: "5px" }}
                                    />
                                    <label
                                      htmlFor=""
                                      style={{ fontSize: "12px" }}>
                                      {x.attributes?.title?.substring(0, 10)}
                                    </label>
                                  </li>
                                </Accordion.Body>
                              ))}
                            </Accordion.Item>
                          </Accordion>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/*/ End Single Widget */}
                  {/* Shop By Price */}
                  <div class="single-widget range newcard">
                    <h3 class="title">Shop by Price</h3>
                    <div class="price-filter"></div>
                    <ul class="check-box-list">
                      <li>
                        <label class="checkbox-inline" for="11">
                          <input
                            name="news"
                            id="11"
                            type="radio"
                            min={1}
                            max={100000}
                            value={1 - 100000}
                            onChange={handleprice}
                          />{" "}
                          <span>All Price</span>
                        </label>
                      </li>
                      <li>
                        <label class="checkbox-inline" for="1">
                          <input
                            name="news"
                            id="1"
                            type="radio"
                            min={1}
                            max={500}
                            value={1 - 500}
                            onChange={handleprice}
                          />{" "}
                          <span>
                            1-500 {currency == "ETB" ? " ETB" : " USD"}
                          </span>
                        </label>
                      </li>
                      <li>
                        <label class="checkbox-inline" for="2">
                          <input
                            name="news"
                            id="2"
                            type="radio"
                            onChange={handleprice}
                            value={500 - 1000}
                          />{" "}
                          <span>
                            500-1000 {currency == "ETB" ? " ETB" : " USD"}
                          </span>
                        </label>
                      </li>
                      <li>
                        <label class="checkbox-inline" for="3">
                          <input
                            name="news"
                            id="3"
                            type="radio"
                            onChange={handleprice}
                            value={1000 - 5000}
                          />{" "}
                          <span>
                            1000-5000 {currency == "ETB" ? " ETB" : " USD"}
                          </span>
                        </label>
                      </li>
                      <li>
                        <label class="checkbox-inline" for="4">
                          <input
                            name="news"
                            id="4"
                            type="radio"
                            onChange={handleprice}
                            value={5000 - 10000}
                          />{" "}
                          <span>
                            5000-10,000 {currency == "ETB" ? " ETB" : " USD"}
                          </span>
                        </label>
                      </li>
                      <li>
                        <label class="checkbox-inline" for="5">
                          <input
                            name="news"
                            id="5"
                            type="radio"
                            onChange={handleprice}
                            value={10000 - 50000}
                          />{" "}
                          <span>
                            10,000-50,000 {currency == "ETB" ? " ETB" : " USD"}
                          </span>
                        </label>
                      </li>
                      <li>
                        <label class="checkbox-inline" for="6">
                          <input
                            name="news"
                            id="6"
                            type="radio"
                            onChange={handleprice}
                            value={50000 - 100000}
                          />{" "}
                          <span>
                            50,000-100,000 {currency == "ETB" ? " ETB" : " USD"}
                          </span>
                        </label>
                      </li>
                    </ul>
                  </div>

                  {/*/ End Shop By Price */}
                  {/* Single Widget */}
                  <div class="single-widget recent-post newcard">
                    <h3 class="title">Recent post</h3>
                    {/* Single Post */}
                    <Recentpost></Recentpost>
                    {/* End Single Post */}
                  </div>
                  {/*/ End Single Widget */}
                  {/* Single Widget */}

                  {/*/ End Single Widget */}
                </div>
              <div class="col-lg-3 col-md-4 ">

              </div>

            </div>
          </div>

                      </Accordion.Body></Accordion.Item></Accordion>
                    {/* Shop Top */}
                    <div class="   ">
                      <div  style={{ padding:'10px',backgroundColor:'white',borderRadius: '10px',display:'flex',flexDirection:'row' ,boxShadow:" rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"}} >
                        <div  >
                          <label>Show :</label>
                          <select
                            name="pagination"
                            onChange={(e) => setPER_PAGE(e.target.value)}>
                            <option value={4}>04</option>
                            <option value={8} >
                              08
                            </option>
                            <option value={16}>16</option>
                            <option value={32} selected>32</option>
                            <option value={48}>48</option>
                            <option value={60}>60</option>
                            <option value={72}>72</option>
                          </select>
                        </div>
                        <div  >
                          <label>Order By :</label>
                          <select
                            type="text"
                            onChange={(e) => setSort(e.target.value)}>
                            <option value="asc">
                              Ascending
                            </option>
                            <option value="desc" selected>descending</option>
                            {/* <option value='size'> Size</option> */}
                          </select>
                        </div>
                        <div  >
                          <label>Sort By :</label>
                          <select
                            type="text"
                            onChange={(e) => setSortby(e.target.value)}>
                            <option value="title" >
                              Name
                            </option>
                            <option value="createdAt" selected >Date</option>
                            <option value="price">Price</option>
                            {/* <option value='size'> Size</option> */}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/*/ End Shop Top */}
                </div>


               {/*  mobile view end */}
                <div class="shop-sidebar shop3">
                  {/* Single Widget */}
                  <div class="single-widget category newcard">
                    <h3 class="title">Categories</h3>
                    <ul class="categor-list">
                      {categ_new_data?.map((x) => (
                        <li key={x.id}>
                          <Accordion
                            style={{
                              marginLeft: "-1rem",

                              overflow: "hidden",
                            }}>
                            <Accordion.Item
                              eventKey="0"
                              // className="bg-gradient-primary"
                              style={{
                                backgroundColor: "#CB0C9f",

                                borderRadius: "0.4rem",
                                padding: "0.05rem",
                              }}>
                              <Accordion.Header>
                                <div
                                  style={{
                                    backgroundColor: "none",
                                    borderColor: "red",
                                    color: "white",
                                    fontSize: "0.7rem",
                                    fontWeight: "bold",
                                  }}>
                                  {" "}
                                  {x.attributes?.title?.substring(0, 20)}
                                </div>
                              </Accordion.Header>
                              {x.attributes?.sub_categories?.data?.map((x) => (
                                <Accordion.Body
                                  style={{ backgroundColor: "none" }}>
                                  <li key={x.id}>
                                    <input
                                      type="checkbox"
                                      id={x.id}
                                      value={x.id}
                                      onChange={handleChange}
                                      style={{ margin: "5px" }}
                                    />
                                    <label
                                      htmlFor=""
                                      style={{ fontSize: "12px" }}>
                                      {x.attributes?.title?.substring(0, 10)}
                                    </label>
                                  </li>
                                </Accordion.Body>
                              ))}
                            </Accordion.Item>
                          </Accordion>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {/*/ End Single Widget */}
                  {/* Shop By Price */}
                  <div class="single-widget range newcard">
                    <h3 class="title">Shop by Price</h3>
                    <div class="price-filter"></div>
                    <ul class="check-box-list">
                      <li>
                        <label class="checkbox-inline" for="11">
                          <input
                            name="news"
                            id="11"
                            type="radio"
                            min={1}
                            max={100000}
                            value={1 - 100000}
                            onChange={handleprice}
                          />{" "}
                          <span>All Price</span>
                        </label>
                      </li>
                      <li>
                        <label class="checkbox-inline" for="1">
                          <input
                            name="news"
                            id="1"
                            type="radio"
                            min={1}
                            max={500}
                            value={1 - 500}
                            onChange={handleprice}
                          />{" "}
                          <span>
                            1-500 {currency == "ETB" ? " ETB" : " USD"}
                          </span>
                        </label>
                      </li>
                      <li>
                        <label class="checkbox-inline" for="2">
                          <input
                            name="news"
                            id="2"
                            type="radio"
                            onChange={handleprice}
                            value={500 - 1000}
                          />{" "}
                          <span>
                            500-1000 {currency == "ETB" ? " ETB" : " USD"}
                          </span>
                        </label>
                      </li>
                      <li>
                        <label class="checkbox-inline" for="3">
                          <input
                            name="news"
                            id="3"
                            type="radio"
                            onChange={handleprice}
                            value={1000 - 5000}
                          />{" "}
                          <span>
                            1000-5000 {currency == "ETB" ? " ETB" : " USD"}
                          </span>
                        </label>
                      </li>
                      <li>
                        <label class="checkbox-inline" for="4">
                          <input
                            name="news"
                            id="4"
                            type="radio"
                            onChange={handleprice}
                            value={5000 - 10000}
                          />{" "}
                          <span>
                            5000-10,000 {currency == "ETB" ? " ETB" : " USD"}
                          </span>
                        </label>
                      </li>
                      <li>
                        <label class="checkbox-inline" for="5">
                          <input
                            name="news"
                            id="5"
                            type="radio"
                            onChange={handleprice}
                            value={10000 - 50000}
                          />{" "}
                          <span>
                            10,000-50,000 {currency == "ETB" ? " ETB" : " USD"}
                          </span>
                        </label>
                      </li>
                      <li>
                        <label class="checkbox-inline" for="6">
                          <input
                            name="news"
                            id="6"
                            type="radio"
                            onChange={handleprice}
                            value={50000 - 100000}
                          />{" "}
                          <span>
                            50,000-100,000 {currency == "ETB" ? " ETB" : " USD"}
                          </span>
                        </label>
                      </li>
                    </ul>
                  </div>

                  {/*/ End Shop By Price */}
                  {/* Single Widget */}
                  <div class="single-widget recent-post newcard">
                    <h3 class="title">Recent post</h3>
                    {/* Single Post */}
                    <Recentpost></Recentpost>
                    {/* End Single Post */}
                  </div>
                  {/*/ End Single Widget */}
                  {/* Single Widget */}

                  {/*/ End Single Widget */}
                </div>
              </div>
              <div class="col-lg-9 col-md-8 col-12">
                <div class="row">
                  <div class="col-12 shop3" >
                    {/* Shop Top */}
                    <div class="shop-top newcard" style={{ marginTop: "0rem" ,}}>
                      <div class="shop-shorter">
                        <div class="single-shorter">
                          <label>Show :</label>
                          <select
                            name="pagination"
                            onChange={(e) => setPER_PAGE(e.target.value)}>
                            <option value={4}>04</option>
                            <option value={8} >
                              08
                            </option>
                            <option value={16} >16</option>
                            <option value={32} selected>32</option>
                            <option value={48} >48</option>
                            <option value={60}>60</option>
                            <option value={72}>72</option>
                          </select>
                        </div>
                        <div class="single-shorter">
                          <label>Order By :</label>
                          <select
                            type="text"
                            onChange={(e) => setSort(e.target.value)}>
                            <option value="asc" >
                              Ascending
                            </option>
                            <option value="desc" selected >descending</option>
                            {/* <option value='size'> Size</option> */}
                          </select>
                        </div>
                        <div class="single-shorter">
                          <label>Sort By :</label>
                          <select
                            type="text"
                            onChange={(e) => setSortby(e.target.value)}>
                            <option value="title" >
                              Name
                            </option>
                            <option value="createdAt" selected >Date</option>
                            <option value="price">Price</option>
                            {/* <option value='size'> Size</option> */}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/*/ End Shop Top */}
                  </div>
                </div>
                <div class="  ">
                  <List
                    PER_PAGE={PER_PAGE}
                    offset={offset}
                    categ={categ}
                    search={search}
                    price={price}
                    sort={sort}
                    price_min={price_min}
                    price_max={price_max}
                    selectedCats={selectedCats}
                    sortby={sortby}
                    subCats={selectedCats}
                    id={id}
                    currency={currency}
                  />
                </div>

                <div className="row" style={{ padding: "3rem",marginBottom:'2rem' }}>
                  <div className="col-12">
                    <nav aria-label="navigation">
                      <div>
                        <ul class="pagination justify-content-center  ">
                          <li class="page-link col-sm-1" onClick={previousPage}>
                            <a href="#">Previous</a>
                          </li>
                          <li class=" page-link col-sm-1">
                            <a href="#">
                              {currentPage + 1}/{pageCount}
                            </a>
                          </li>

                          <li class="page-link col-sm-1" onClick={nextPage}>
                            <a href="#">Next</a>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*/ End Product Style 1  */}

        {/* Modal end */}
      </div>
    </div>
  );
};

export default Shopgrid;
