import React, { Component } from "react";
import chapa from "../chapa_img.jpg";
const Foot = () => {
  return (
    <div   className=" hidden-foot">
      {/* Start Footer Area */}
      <footer class="footer newcard ">
        {/* Footer Top */}
        <div class="footer-top  " style={{ padding: "2rem" }}>
          <div class="container">
            <div class="row">
              <div class="col-lg-5 col-md-6 col-12">
                {/* Single Widget */}
                <div class="single-footer about">
                  <div class="logo">
                    <a href="index.html">
                      {/* <img src="images/logo2.png" alt="#" /> */}
                    </a>
                  </div>
                  <p class="text">
                    Ethoexpress is your go-to online store for all your needs.
                    We provide high-quality products with excellent customer
                    service.
                  </p>
                  <p class="call">
                    Got Question? Call us 24/7
                    <span>
                      <a href="tel:+251911 51 42 74">+251 911 51 42 74</a>
                    </span>
                    <span>
                      <a href="tel:+251 91 306 8725">+251 91 306 8725</a>
                    </span>
                    <span>
                      <a href="tel:+393407781457">+39 3407781457</a>
                    </span>
                  </p>
                </div>
                {/* End Single Widget */}
              </div>
              <div class="col-lg-2 col-md-6 col-12">
                {/* Single Widget */}
                <div class="single-footer links">
                  <h4>Information</h4>
                  <ul>
                    <li>
                      <a href="#">About Us</a>
                    </li>
                    <li>
                      <a href="#">Faq</a>
                    </li>
                    <li>
                      <a href="#">Terms & Conditions</a>
                    </li>
                    <li>
                      <a href="#">Contact Us</a>
                    </li>
                    <li>
                      <a href="#">Help</a>
                    </li>
                  </ul>
                </div>
                {/* End Single Widget */}
              </div>
              <div class="col-lg-2 col-md-6 col-12">
                {/* Single Widget */}
                <div class="single-footer links">
                  <h4>Customer Service</h4>
                  <ul>
                    <li>
                      <a href="#">Payment Methods</a>
                    </li>
                    <li>
                      <a href="#">Money-back</a>
                    </li>
                    <li>
                      <a href="#">Returns</a>
                    </li>
                    <li>
                      <a href="#">Shipping</a>
                    </li>
                    <li>
                      <a href="#">Privacy Policy</a>
                    </li>
                  </ul>
                </div>
                {/* End Single Widget */}
              </div>
              <div class="col-lg-3 col-md-6 col-12">
                {/* Single Widget */}
                <div class="single-footer social">
                  <h4>Get In Touch</h4>
                  {/* Single Widget */}
                  <div class="contact">
                    <ul>
                      <li>Via Pescaglia 93 / 00146 Roma /Italy</li>
                      <li>Email: support@ethoexpress.com </li>
                      <li>Email: aberaberhane@gmail.com</li>
                      <li>
                        {" "}
                        +251 911 51 42 74 / +251 91 306 8725 +39 3407781457
                      </li>
                    </ul>
                  </div>
                  {/* End Single Widget */}
                  <ul>
                    <li>
                      <a href="#">
                        <i class="ti-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="ti-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="ti-flickr"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="ti-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                {/* End Single Widget */}
              </div>
            </div>
          </div>
        </div>
        {/* End Footer Top */}
        <div class="copyright">
          <div class="container">
            <div class="inner">
              <div class="row">
                <div class="col-lg-6 col-12">
                  <div class="left">
                    <p>
                      Copyright © 2024 <a href=" " target="_blank"></a> - All
                      Rights Reserved.
                    </p>
                  </div>
                </div>
                <div class="col-lg-6 col-12">
                  <div class="right">
                    <img src={chapa} alt="#" width={50} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* /End Footer Area */}
    </div>
  );
};

export default Foot;
