import React, { Component } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { currencyData, ExchangeData } from "../currency";
import onsalesvg from "../asset/images/sale-svgrepo-com (1).svg";
const Categories = () => {
  const { id } = useParams();

  console.log(id, "id");
  const { data, loading, error } = useFetch(
    `/products?populate=*&[filters][category][id]=${id}
    }`
  );
  console.log(data, "this is data");
  const currency = currencyData();
  const [index, setIndex] = useState(1);
  const Rate = ExchangeData();
  // const currency = currencyData();
  // console.log("currency", currency);

  const Z = currency == "ETB" ? 1 : Rate;

  // console.log("Z", Z);
  // console.log("PRICE", PRICE);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }
  //console.log(selectedCats, "the search");
  //console.log(subcateg, "the search");
  const [PER_PAGE, setPER_PAGE] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedsubCats, setSelectedsubCats] = useState([]);
  //console.log(currentPage, 'current page')
  //console.log(PER_PAGE, 'PER_PAGE page')

  const offset = currentPage * PER_PAGE;
  //console.log(offset, 'offset')
  const pageCount = Math.ceil(data?.length / PER_PAGE);
  const previousPage = () => {
    if (currentPage !== 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage !== pageCount - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  return (
    <div className="row" style={{ paddingLeft: "1rem", paddingBottom: "3rem" }}>
      {" "}
      {data?.map((x) => (
        <div key={x.id} className="col-sm-6 col-md-6 col-lg-3">
          <Link to={`/product/${x.id}`}>
            <div
              class="single-product     newcard      "
              style={{ padding: "0.7rem" }}>
              <div class="product-img">
                <a>
                  <img
                    class="default-img"
                    src={
                      process.env.REACT_APP_UPLOAD_URL +
                      x.attributes?.gallery?.data[0]?.attributes?.url
                    }
                    alt={x?.attributes?.title}
                    style={{ height: "15rem", objectFit: "cover" }}
                  />
                  <img
                    class="hover-img"
                    src={
                      process.env.REACT_APP_UPLOAD_URL +
                      x?.attributes?.gallery?.data[1]?.attributes?.url
                    }
                    alt={x?.attributes?.title}
                    style={{ height: "15rem", objectFit: "cover" }}
                  />
                </a>
                <div class="button-head">
                  <div class="product-action">
                    <a
                      data-toggle="modal"
                      data-target="#exampleModal"
                      title="Quick View"
                      href="#">
                      <i class=" ti-eye"></i>
                      <span>Quick Shop</span>
                    </a>
                    <a title="Wishlist" href="#">
                      <i class=" ti-heart "></i>
                      <span>Add to Wishlist</span>
                    </a>
                    <a title="Compare" href="#">
                      <i class="ti-bar-chart-alt"></i>
                      <span>Add to Compare</span>
                    </a>
                  </div>
                  <div class="product-action-2">
                    <a title="Add to cart" href="#">
                      Add to cart
                    </a>
                  </div>
                </div>
              </div>
              <div class="product-content">
                <h3>
                  <a>{x?.attributes?.title}</a>
                </h3>
                <h5>
                  <a>{x?.attributes?.desc?.substring(0, 40)}</a>
                </h5>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  {x?.attributes?.status ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}>
                      <div
                        style={{
                          width: "0.8rem",
                          height: "0.8rem",
                          borderRadius: "50%",
                          backgroundColor: "green",
                          marginTop: "0.15rem",
                        }}></div>{" "}
                      <div
                        style={{
                          color: "green",
                          fontWeight: "bold",
                          marginLeft: "0.3rem",
                        }}>
                        IN Stock{" "}
                      </div>{" "}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}>
                      <div
                        style={{
                          width: "0.8rem",
                          height: "0.8rem",
                          borderRadius: "50%",
                          backgroundColor: "red",
                          marginTop: "0.15rem",
                        }}></div>
                      <div
                        style={{
                          color: "red",
                          fontWeight: "bold",
                          marginLeft: "0.3rem",
                        }}>
                        Out Of Stock{" "}
                      </div>{" "}
                    </div>
                  )}

                  {x?.attributes?.onsale ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}>
                      <img
                        src={onsalesvg}
                        alt=""
                        style={{
                          width: "2rem",
                          marginLeft: "0.5rem",
                          marginTop: "-0.5rem",
                        }}
                      />
                    </div>
                  ) : (
                    " "
                  )}
                </div>
                <h5></h5>
                <div class="product-price">
                  <span>
                    {" "}
                    {(x.attributes?.price * Z).toFixed(2)}
                    {currency == "ETB" ? " ETB" : "$"}
                  </span>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
      <div className="row" style={{ padding: "3rem" }}>
        <div className="col-12">
          <nav aria-label="navigation">
            <div>
              <ul class="pagination justify-content-center  ">
                <li class="page-link col-sm-1" onClick={previousPage}>
                  <a href="#">Previous</a>
                </li>
                <li class=" page-link col-sm-1">
                  <a href="#">
                    {currentPage + 1}/{pageCount}
                  </a>
                </li>

                <li class="page-link col-sm-1" onClick={nextPage}>
                  <a href="#">Next</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Categories;
