import React, { Component } from "react";
import Collectons from "../component/Collectons";
import Mainpost from "../component/Mainpost";
import Blog from "../component/Blog";
import useFetch from "../hooks/useFetch";
import { makeRequest } from "../MakeReq/makeRequest";
import Dealoftheday from "../component/Dealoftheday";
import Trending from "../component/Trending";
import OnsaleBest_Seller_Top_Viewed from "../component/OnsaleBest_Seller_Top_Viewed";
import Mainpost_two from "../component/Mainpost_two";
import Intro from "../component/Intro";
import "../App.css";
const Home = () => {
  return (
    <div >
      <div class="js" style={{ marginTop: "0rem" }}>
        {/* Preloader */}
        {/* <div class="preloader">
  <div class="preloader-inner">
    <div class="preloader-icon">
      <span></span>
      <span></span>
    </div>
  </div>
</div> */}
        {/* End Preloader */}

        {/* Start Small Banner  */}
        <div style={{ width: "30rem" }}></div>

        <Intro></Intro>
        <Mainpost></Mainpost>
        <Collectons></Collectons>

        {/* End Small Banner */}

        {/* Start Product Area */}

        {/* <Trending></Trending> */}

        {/* End Product Area */}
        {/* <Mainpost_two></Mainpost_two> */}

        {/* Start Midium Banner  */}

        {/* End Midium Banner */}

        {/* Start Shop Home List  */}
        <section class="shop-home-list  ">
          <div  >
            <div class="row">
              <OnsaleBest_Seller_Top_Viewed></OnsaleBest_Seller_Top_Viewed>
            </div>
          </div>
        </section>
        {/* End Shop Home List  */}

        {/* Start Cowndown Area */}
        <Dealoftheday></Dealoftheday>
        {/* /End Cowndown Area */}

        {/* Start Shop Blog  */}
        {/* <Blog></Blog> */}
        {/* End Shop Blog  */}



        {/* Jquery */}
      </div>
    </div>
  );
};
export default Home;
