import React, { Component } from "react";
import { Link } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { makeRequest } from "../MakeReq/makeRequest";

const Mainpost = () => {
  const { data, loading, error } = useFetch(`/main-posts?populate=*`);
  // console.log(data, "main post ");
  return (
    <div className="  row">
        <div style={{ color:'black',fontSize:'30px',marginBottom:'30px',fontWeight:'bold',textTransform:'capitalize'}}>upcoming products</div>
      {data?.map((x) => (
        <div key={x.id} className="col-6 col-md-6 col-lg-3">
          {/* Slider Area */}
          <div
            class="hero-slider  newcard"
            style={{ marginBottom: "10%" }}>
            {/* Single Slider */}
            <div

              style={{
                backgroundImage: `url(${
                  process.env.REACT_APP_UPLOAD_URL +
                  x?.attributes?.image?.data?.attributes?.url
                })`,height: "200px",overflow:'hidden',padding:"20px",borderRadius:'10px',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                display: "grid",
                placeItems: "center",verticalAlign:'center'
              }}>
                <h1 style={{ color: "white", fontSize: "1.8rem" }}>
                              <span
                                style={{ color: "white", fontSize: "1rem" }}>
                                {x.attributes?.discount}
                              </span>
                              <span
                                style={{ color: "white", fontSize: "1.8rem" }}>
                                {" "}
                                {x.attributes?.title}
                              </span>
                            </h1>
                            <p style={{ color: "white" }}>
                              {data?.attributes?.description}
                            </p>
                            <div class="button ">
                              <Link to="/">
                                <a class=" btn "  style={{ color: "white", fontSize: "0.6rem" ,backgroundColor:'black'}}>Shop Now!</a>
                              </Link>
                            </div>

            </div>
            {/*/ End Single Slider */}
          </div>
          {/*/ End Slider Area */}
        </div>
      ))}
    </div>
  );
};

export default Mainpost;
