import "../App.css";
import React from "react";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { storeCurrency, storeExchange } from "../currency";
// import logo from ".././";
import useFetch from "../hooks/useFetch";
import { Link, Routes, Route, useNavigate } from "react-router-dom";
import Search from "./Search";
import {
  incrementQuantity,
  decrementQuantity,
  removeItem,
} from "../../src/redux/cartSlice";
import { UserData } from "../helper";
import { useLocation } from "react-router-dom";
import { currencyData, ExchangeData } from "../currency";

import logo1 from "../asset/images/2psd.png";
const Sidebar = () => {
  const [isHovered, setIsHovered] = useState();
  // exchange api
  const Exchange = useFetch(`/converts?populate=&`);
  const USD = Exchange?.data?.map((x) => x.attributes?.amount);

  //  console.log(USD, "USD");
  const USD_RATE = ExchangeData();
  //  console.log(USD_RATE, "USD_RATE");

  const [currency, setCurrency] = useState(currencyData());
  //  const currency = currencyData();
  //  console.log(Exchange?.data, "Exchange");

  const theprice = currency == "ETB" ? 100 * 1 : 100 * USD;

  // console.log(theprice, "theprice");

  const location = useLocation();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  // console.log(windowSize)
  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    // Set initial window size
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const navigate = useNavigate();
  const { data, loading, error } = useFetch(`/categories?populate=products`);
  // get cart state
  const Cart_Products = useSelector((state) => state.Cart_Products);
  const dispatch = useDispatch();
  const final_pay = Cart_Products.reduce(
    (total, item) => total + item.price * item.quantity,
    0
  );
  const getTotalQuantity = () => {
    let total = 0;
    Cart_Products.forEach((item) => {
      total += item.quantity;
    });
    //  console.log(UserData() ? 'gg':'hh','UserData')
    return total;
  };

  const logout = () => {
    localStorage.removeItem("user", {});
    navigate("/");
  };
  return (
    <div className=" ">
      <>
        <aside
          className="  sidenav navbar navbar-vertical navbar-expand-xs   border-radius-xl my-3 fixed-start ms-3  "
          style={{

            overflow: "hidden",
            // boxShadow:
            //   " rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
          }}
          id="sidenav-main">
          <div className="sidenav-header "  >
            <i
              className="fas fa-times p-3 cursor-pointer text-secondary opacity-5 position-absolute end-0 top-0 d-none d-xl-none"
              aria-hidden="true"
              id="iconSidenav"
            />
            <Link to="/">
              <a>
                <img
                  src={logo1}
                  className="  "
                  style={{ height: "8rem", paddingLeft: "1.5rem" }}
                  alt="main_logo"
                />
                {/* <span
                  className="ms-1 font-weight-bold "
                  style={{
                    fontSize: "1.5rem",
                  }}>
                  Etho Express
                </span> */}
              </a>
            </Link>
          </div>
          <br></br>

          <Search></Search>
          <div
            className="collapse navbar-collapse  w-auto "
            id="sidenav-collapse-main">
            <ul className="navbar-nav">
              <li className="nav-item">
                <Link to="/Home">
                  <a
                    className={
                      location.pathname == "Home"
                        ? "nav-link  active  bg-gradient-primary2"
                        : "nav-link "
                    }
                    href="/">
                    <div className=" icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center ">
                      <i class="fa fa-home" aria-hidden="true"></i>
                    </div>
                    <span className="nav-link-text ms-1">Home</span>
                  </a>
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/">
                  <a
                    className={
                      location.pathname == "/"
                        ? "nav-link  active"
                        : "nav-link "
                    }>
                    <div className=" icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center ">
                      <i class="fa fa-shopping-bag" aria-hidden="true"></i>
                    </div>
                    <span className="nav-link-text ms-1">Shop</span>
                  </a>
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link to="/About">
                  <a
                    className={
                      location.pathname == "/About"
                        ? "nav-link  active"
                        : "nav-link "
                    }>
                    <div className=" icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                      <svg
                        width="12px"
                        height="12px"
                        viewBox="0 0 42 42"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink">
                        <title>box-3d-50</title>
                        <g
                          stroke="none"
                          strokeWidth={1}
                          fill="none"
                          fillRule="evenodd">
                          <g
                            transform="translate(-2319.000000, -291.000000)"
                            fill="#FFFFFF"
                            fillRule="nonzero">
                            <g transform="translate(1716.000000, 291.000000)">
                              <g transform="translate(603.000000, 0.000000)">
                                <path
                                  className="color-background"
                                  d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L20.2741875,0.1378125 C19.905375,-0.04725 19.469625,-0.04725 19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 C2.75080129,9.67332903 2.90771305,9.83023153 3.10122239,9.9269862 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z"
                                />
                                <path
                                  className="color-background opacity-6"
                                  d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 C24.7802551,40.8805372 24.9333778,40.8443874 25.0722402,40.7749511 L41.2741875,32.673375 L41.2741875,32.673375 C41.719125,32.4515625 42,31.9974375 42,31.5 L42,14.241659 C42,13.6893742 41.5522847,13.241659 41,13.241659 C40.8447549,13.241659 40.6916418,13.2778041 40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z"
                                />
                                <path
                                  className="color-background opacity-6"
                                  d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 C0.0361450918,12.6208008 6.47121774e-16,12.7739139 0,12.929159 L0,30.1875 L0,30.1875 C0,30.6849375 0.280875,31.1390625 0.7258125,31.3621875 L19.5528096,40.7750766 C20.0467945,41.0220531 20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 C21,22.0503869 20.7859976,21.7041238 20.4472136,21.5347318 Z"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <span className="nav-link-text ms-1">About</span>
                  </a>
                </Link>
              </li> */}
              <li className="nav-item">
                <Link to="cart">
                  <a
                    className={
                      location.pathname == "/cart"
                        ? "nav-link  active"
                        : "nav-link "
                    }>
                    <div className=" icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                      <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                    </div>
                    <span className="nav-link-text ms-1">
                      Cart (<span>{getTotalQuantity() || 0} Items</span>)
                    </span>
                  </a>
                </Link>
              </li>

              <li className="nav-item mt-3">
                <h6 className="ps-4 ms-2 text-uppercase text-xs font-weight-bolder opacity-6">
                  Account pages
                </h6>
              </li>
              <li className="nav-item">
                <Link to="/profile">
                  <a
                    className={
                      location.pathname == "/profile"
                        ? "nav-link  active"
                        : "nav-link "
                    }>
                    <div className=" icon-sm shadow border-radius-md bg-white text-center me-2 d-flex align-items-center justify-content-center">
                      <i class="fa fa-user" aria-hidden="true"></i>
                    </div>
                    <span className="nav-link-text ms-1">Profile</span>{" "}
                    <br></br>
                  </a>
                </Link>
              </li>
              <li></li>
              <li></li>
            </ul>
          </div>
          <div
            className="sinlge-bar"
            style={{ marginTop: "0.1rem", marginLeft: "1.3rem" }}>
            <div className="shop-shorter">
              <div className="single-shorter ">
                <select
                  className="newcard"
                  style={{
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                  }}
                  type="text"
                  name="pagination"
                  onChange={(e) => {
                    setCurrency(e.target.value);
                    storeCurrency(e.target.value);
                    storeExchange(USD);
                    window.location.reload();
                  }}>
                  <option
                    value={currency}
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}>
                    {currency}
                  </option>
                  <option
                    value={"ETB"}
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}>
                    ETB
                  </option>
                  <option
                    value={"USD"}
                    style={{
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                    }}>
                    USD
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div    style={{ marginTop: "0.5rem", marginLeft: "0.2rem" }}>
            <ul className="list-main">
              {UserData() ? (
                <li>
                  <i className="ti-power-off"></i>
                  <button
                    type="button"
                    onClick={logout}
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      marginTop: "10px",
                      marginLeft: "20px",
                      border: "none",
                    }}>
                    Sign Out
                  </button>
                </li>
              ) : (
                <li>
                  <i className="ti-power-off"></i>
                  <button
                    onClick={(e) => navigate("/Signin")}
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: "20px",
                      paddingRight: "20px",
                      marginTop: "10px",
                      marginLeft: "20px",
                      border: "none",
                    }}>
                    Sign in
                  </button>
                </li>
              )}
            </ul>
          </div>
        </aside>
        <div className="mobileView" style={{ marginBottom: "6rem" }}>
          <div className="container position-sticky z-index-sticky top-0 ">
            <div className="row">
              <div className="col-12">
                {/* Navbar */}
                <nav className="navbar navbar-expand-lg blur blur-rounded top-0 z-index-3 shadow position-absolute my-3 py-2 start-0 end-0 mx-4">
                  <div className="container-fluid pe-0 ">
                    <Link to="/Home">
                      <a
                        className="navbar-brand font-weight-bolder ms-lg-0 ms-3 "
                        href="/Home">
                        <i class="fa fa-home" aria-hidden="true"></i>
                      </a>
                    </Link>
                    <Link to="/">
                      <a
                        className="navbar-brand font-weight-bolder ms-lg-0 ms-3 "
                        href="">
                        <i class="fa fa-shopping-bag" aria-hidden="true"></i>
                      </a>
                    </Link>
                    <Link></Link>
                    <a
                      className="navbar-brand font-weight-bolder ms-lg-0 ms-3 "
                      href="/">
                      <i class="fa fa-shopping-cart" aria-hidden="true">
                        {" "}
                        &nbsp;
                      </i>
                      <span>(0)</span>
                    </a>
                    <a
                      className="navbar-brand font-weight-bolder ms-lg-0 ms-3 "
                      href="../pages/dashboard.html">
                      <i class="fa fa-user" aria-hidden="true"></i>
                    </a>
                    <a
                      className="navbar-brand font-weight-bolder ms-lg-0 ms-3 "
                      href="../pages/dashboard.html">
                      <i class="fa fa-language" aria-hidden="true"></i>
                    </a>
                    <a
                      className="navbar-brand font-weight-bolder ms-lg-0 ms-3 "
                      href="../pages/dashboard.html">
                      Currency
                    </a>
                    <a
                      className="navbar-brand font-weight-bolder ms-lg-0 ms-3 "
                      href="../pages/dashboard.html">
                      <i class="fa fa-sign-in" aria-hidden="true"></i>
                    </a>
                    <a
                      className="navbar-brand font-weight-bolder ms-lg-0 ms-3 "
                      href="../pages/dashboard.html">
                      <i class="fa fa-sign-out" aria-hidden="true"></i>
                    </a>
                  </div>
                </nav>
                {/* End Navbar */}
              </div>
            </div>
          </div>
        </div>
        {/*   Core JS Files   */}
        {/* Github buttons */}
        {/* Control Center for Soft Dashboard: parallax effects, scripts for the example pages etc */}
      </>
    </div>
  );
};
export default Sidebar;
